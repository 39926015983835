import { useEffect, useRef, useState } from "react";
import { useEventsContext } from "../../contexts/EventsContext";
import strings from "../../i18n/i18nStrings";
import PhotoRoll from "../photoRoll/PhotoRoll";
import styles from "./EventDisplay.module.scss";
import { lampPostFetch } from "../../utils/fetchHelpers";
import { GET_EVENT } from "../common/constants/endpoints";
import { EventType } from "../../types/EventType";
import BackHeader from "../mobile/backHeader/BackHeader";
import Loader from "../loader/Loader";
import EmptyDisplay from "./emptyDisplay/EmptyDisplay";
import { useFilterContext } from "../../contexts/FilterContext";
import { useAppContext } from "../../contexts/AppContext";
import RSVPButton from "../rsvpButton/RSVPButton";
import { FILTER_TAGS } from "../filters/filters.constants";
import InterestChips from "../interestChips/InterestChips";
import { ReactComponent as EditIcon } from '../../assets/icons/PencilIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/XIcon.svg';
import DeleteEvent from "../deleteEvent/DeleteEvent";
import CreateOrEditEvent from "../createOrEditEvent/CreateOrEditEvent";
import ShareEventButton from "../shareEventButton/ShareEventButton";
import ShareEvent from "../shareEvent/ShareEvent";
import { getEventDates } from "../../utils/getEventDates";

const EventDisplay = () => {
    const {
        events,
        eventsLoading,
        selectedEvent,
        onDeselectEvent,
        deleteEvent,
    } = useEventsContext();
    const { filters } = useFilterContext();
    const {
        userLocation,
        userInfo
    } = useAppContext();

    const [eventData, setEventData] = useState<EventType>(null);
    const [eventDataLoading, setEventDataLoading] = useState(false);
    const [canEditEvent, setCanEditEvent] = useState(false);
    const [showEditEvent, setShowEditEvent] = useState(false);
    const [showDeleteEvent, setShowDeleteEvent] = useState(false);
    const [showShareEvent, setShowShareEvent] = useState(false);

    const eventContainer = useRef(null);

    useEffect(() => {
        setEventData(null);
        setShowEditEvent(false);

        if (selectedEvent) {
            setEventDataLoading(true);

            let locationValue;

            if (filters?.location) {
                locationValue = filters.location
            } else {
                locationValue = {
                    lat: userLocation?.latitude,
                    lng: userLocation?.longitude,
                }
            }

            lampPostFetch(
                `${GET_EVENT(selectedEvent)}?locLat=${locationValue.lat}&locLng=${locationValue.lng}`,
                {},
                (newEventData: EventType) => {
                    setEventDataLoading(false);

                    if (newEventData._id) {
                        setEventData(newEventData);
                    } else {
                        onDeselectEvent();
                    }
                }
            );
        }

        if (eventContainer.current) {
            eventContainer.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [
        filters.location,
        selectedEvent,
        userLocation?.latitude,
        userLocation?.longitude,
        events,
        onDeselectEvent
    ]);

    useEffect(() => {
        setCanEditEvent(
            userInfo?.events?.created.find(eventId => eventId === eventData?._id) &&
            eventData?.startDateTime > new Date().getTime()
        );
    }, [eventData?._id, eventData?.startDateTime, userInfo?.events?.created]);

    const formatAddress = () => {
        const address = eventData?.location?.address || eventData?.location?.formatted_address;

        if (address) {
            const splitAddress = address.split(',');

            const separatedAddress = [
                splitAddress[0],
                splitAddress[1],
                splitAddress[2],
            ];

            if (parseFloat(eventData.distance) > 0) {
                separatedAddress.push(`(${eventData.distance})`)
            }

            return separatedAddress;
        }
    }

    const onCancelEventEdit = () => setShowEditEvent(false);
    const onCancelDeleteEvent = () => setShowDeleteEvent(false);
    const onCancelShareEvent = () => setShowShareEvent(false);

    return (
        <div className={styles.eventDisplayContainer}>
            <div className={styles.eventPreviewContainer}>
                {eventData && !eventsLoading ? (
                    <>
                        {
                            !showEditEvent && !showDeleteEvent && !showShareEvent && (
                                <BackHeader
                                    onBack={onDeselectEvent}
                                    backText={strings.eventDetailsScreen.backtoEvents}
                                />
                            )}
                        <div
                            className={`
                                ${styles.eventPreview}
                                ${userInfo?._id ? '' : styles.loggedOut}
                                ${showEditEvent || showDeleteEvent || showShareEvent ? styles.modalOpen : ''}
                            `}
                            ref={eventContainer}
                        >
                            {
                                canEditEvent && (
                                    <>
                                        {
                                            !showEditEvent && !showDeleteEvent && !showShareEvent && (
                                                <div className={styles.actionsContainer}>
                                                    <div
                                                        onClick={() => setShowEditEvent(true)}
                                                        className={`${styles.actionButton} ${styles.edit}`}
                                                    >
                                                        <EditIcon />
                                                    </div>
                                                    <div
                                                        onClick={() => setShowDeleteEvent(true)}
                                                        className={`${styles.actionButton} ${styles.delete}`}
                                                    >
                                                        <DeleteIcon />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <CreateOrEditEvent
                                            showCreateEvent={showEditEvent}
                                            onClose={onCancelEventEdit}
                                            eventInfo={eventData}
                                        />
                                        <DeleteEvent
                                            showDeleteEvent={showDeleteEvent}
                                            onClose={onCancelDeleteEvent}
                                            onDelete={() => deleteEvent(eventData._id)}
                                        />
                                    </>
                                )
                            }
                            <ShareEvent
                                showShareEvent={showShareEvent}
                                onClose={onCancelShareEvent}
                            />
                            <div
                                className={`
                                    ${styles.eventDisplayInfoContainer}
                                    ${showEditEvent || showDeleteEvent || showShareEvent ? styles.isClosed : ''}
                                `}
                            >
                                {
                                    eventData?.images?.length ? <PhotoRoll photoUrls={eventData.images} /> : null
                                }
                                <div className={styles.infoContainer}>
                                    <div className={styles.sizedInfo}>
                                        <div className={styles.infoItem}>
                                            <p className={styles.eventName}>{eventData.name}</p>
                                        </div>
                                        <div className={styles.infoItem}>
                                            <p className={styles.eventLocation}>{eventData.location.name}</p>
                                        </div>
                                        <div className={styles.infoItem}>
                                            {formatAddress()?.map((addressPiece, idx) => (
                                                <p
                                                    key={idx}
                                                    className={styles.locationText}
                                                >
                                                    {addressPiece}
                                                </p>
                                            ))}
                                        </div>
                                        <div className={styles.infoItem}>
                                            <p className={styles.infoItemText}>
                                                {
                                                    getEventDates(eventData.startDateTime, eventData.endDateTime)
                                                }
                                            </p>
                                        </div>
                                        {/* {
                                            // TODO: Readd when attendees make since to show
                                            <div className={`${styles.attendeeList} ${styles.infoItem}`}>
                                                <div className={styles.attendeeImagesContainer}>
                                                eventData.attendees.slice(0, 3).map((attendee: { attendeeImage: string | undefined; }, idx: Key | null | undefined) => (
                                                    <div key={idx}>
                                                        <img
                                                            src={attendee.attendeeImage}
                                                            className={styles.attendeeImage}
                                                            alt="Attendee"
                                                        />
                                                    </div>
                                                ))
                                                </div>
                                                    eventData.attendees.length > 3 ? (
                                                        <p className={styles.infoItemText}>
                                                            {strings.eventDetailsScreen.additionalAttendees(eventData.attendees.length - 3)}
                                                        </p>
                                                    ) : null
                                            </div>
                                        } */}
                                        <div className={eventData?.tags?.length ? styles.infoItem : ''}>
                                            <p className={styles.infoItemText}>
                                                {strings.eventDetailsScreen.cost(
                                                    eventData.cost > 0 ? eventData.cost.toFixed(2) : strings.eventDetailsScreen.free
                                                )}
                                            </p>
                                        </div>
                                        {
                                            eventData?.tags?.length ? (
                                                <InterestChips
                                                    interests={eventData?.tags?.map((tagId: any) => Object.values(FILTER_TAGS).find(filterTag => filterTag.id === tagId))}
                                                />
                                            ) : null
                                        }
                                    </div>
                                </div>
                                <p className={styles.eventDescription}>{eventData.description}</p>
                            </div>
                        </div>
                        <div
                            className={`
                                ${styles.rsvpContainer} 
                                ${showEditEvent || showDeleteEvent || showShareEvent ? styles.closedRsvpContainer : ''}
                            `}
                        >
                            <RSVPButton eventData={eventData} />
                            <ShareEventButton
                                onShowShare={() => setShowShareEvent(true)}
                                eventData={eventData}
                            />
                        </div>
                    </>
                ) : (
                    <div>
                        {/* TODO: Replace with skeleton */}
                        {eventDataLoading ? <Loader /> : <EmptyDisplay />}
                    </div>
                )}
            </div>
        </div >
    )
}

export default EventDisplay;