const strings = {
    demographics: {
        gender: {
            male: "Male",
            female: "Female",
            transgender: "Transgender",
            nonBinary: "Non-binary",
            other: "Other",
        },
        orientation: {
            heterosexual: "Heterosexual",
            homosexual: "Homosexual",
            bisexual: "Bisexual",
            other: "Other",
        },
        education: {
            none: "No formal education",
            highSchool: "High School Diploma or equivalent",
            tradeSchool: "Trade School Certification",
            associates: "Associate's Degree (e.g., A.A. or A.S.)",
            bachelors: "Bachelor's Degree (e.g., B.A. or B.S.)",
            masters: "Master's Degree (e.g., M.A. or M.S.)",
            doctorate: "Doctorate (e.g., Ph.D., M.D.)",
            professional: "Professional Degree (e.g., J.D., M.B.A., M.D.)",
        },
        race: {
            white: "White",
            black: "Black or African American",
            hispanic: "Hispanic or Latino",
            asian: "Asian",
            nativeAmericanAlaskan: "Native American or Alaska Native",
            pacificIslander: "Native Hawaiian or Other Pacific Islander",
            other: "Other",
        },
        decline: "Prefer Not to Say",
    }
}

export default strings;
