
import strings from "../../i18n/i18nStrings";
import Modal from "../../ui/modal/Modal";
import BackHeader from "../mobile/backHeader/BackHeader";
import styles from "./ShareEvent.module.scss";
import ShareEventInputs from "./inputs/ShareEventInputs";

const ShareEvent = ({
    showShareEvent,
    onClose,
}: {
    showShareEvent: boolean;
    onClose: () => void;
}) => (
    <>
        {
            showShareEvent && (
                <div className={styles.shareEventMobile}>
                    <>
                        <BackHeader
                            onBack={onClose}
                            backText={strings.eventDetailsScreen.backtoEvent}
                        />
                        <div className={styles.shareForm}>
                            <ShareEventInputs onClose={onClose} />
                        </div>
                    </>
                </div>
            )
        }
        <Modal
            isOpen={showShareEvent}
            onClose={onClose}
            isThin
        >
            <ShareEventInputs onClose={onClose} />
        </Modal>
    </>
);

export default ShareEvent;