import { useEffect, useRef } from 'react';
import styles from './FiltersHeader.module.scss';
import { useFilterContext } from '../../../contexts/FilterContext';
import FiltersInputs from '../filtersInputs/FiltersInputs';
import strings from '../../../i18n/i18nStrings';
import { ReactComponent as FilterIcon } from '../../../assets/icons/FilterIcon.svg';

const FiltersHeader = () => {
    const filtersRef = useRef(null);

    const {
        showFilters,
        setShowFilters,
    } = useFilterContext();

    useEffect(() => {
        const handleOutsideClick = (evt: { target: any; }) => {
            if (
                filtersRef.current &&
                !filtersRef.current.contains(evt.target) &&
                window.innerWidth >= 768 // Only closes automatically if the screen size has the pop-up
            ) {
                setShowFilters(false);
            }
        }

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, [filtersRef, setShowFilters]);

    return (
        <div className={styles.filtersContainer} ref={filtersRef}>
            <div className={styles.sideContainer}>
                <div onClick={() => setShowFilters(!showFilters)}>
                    <FilterIcon className={styles.filterLogo} />
                </div>
                <div>
                    {
                        showFilters && (
                            <div className={styles.filters}>
                                <FiltersInputs />
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={styles.titleContainer}>
                {strings.homeScreen.events}
            </div>
        </div>
    )
}

export default FiltersHeader;