const deleteStrings = {
    deleteEvent: {
        description: 'Deleting this event will remove it permanently from your schedule.',
        undone: 'This action cannot be undone.',
        deleteButton: 'Delete Event'
    },
    deleteGroup: {
        description: 'Deleting this group will remove it permanently from your and your members groups list.',
        undone: 'This action cannot be undone.',
        deleteButton: 'Delete Group'
    },
    deleteUser: {
        description: 'Deleting your account will remove it from our system and remove your recommendations.',
        undone: 'This action cannot be undone.',
        deleteButton: 'Delete Account'
    },
}

export default deleteStrings;