import { useAppContext } from "../../contexts/AppContext";
import strings from "../../i18n/i18nStrings";
import { EventType } from "../../types/EventType";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import styles from "./ShareEventButton.module.scss";

const ShareEventButton = ({
    onShowShare,
    eventData
}: {
    onShowShare: () => void;
    eventData: EventType;
}) => {
    const { isSignedIn } = useAppContext();

    return isSignedIn && new Date().getTime() < eventData.endDateTime ? (
        <div className={styles.buttonContainer}>
            <Button
                onPress={onShowShare}
                roleVariant={BUTTON_VARIANTS.SECONDARY}
            >
                <span>{strings.eventDetailsScreen.share}</span>
            </Button>
        </div>
    ) : null
}

export default ShareEventButton;