import strings from "../../../i18n/i18nStrings";
import Button, { BUTTON_VARIANTS } from "../../../ui/button/Button";
import styles from "./DeleteEventInputs.module.scss";

const DeleteEventInputs = ({
    onClose,
    onDelete,
}: {
    onClose: () => void;
    onDelete: () => void;
}) => {

    return (
        <div>
            <p className={styles.text}>{strings.deleteEvent.description}</p>
            <p className={styles.text}>{strings.deleteEvent.undone}</p>
            <div className={styles.deleteEventInputs}>
                <div>
                    <Button
                        onPress={onClose}
                        roleVariant={BUTTON_VARIANTS.SECONDARY}
                    >
                        <span>{strings.common.cancel}</span>
                    </Button>
                </div>
                <div>
                    <Button
                        onPress={onDelete}
                        roleVariant={BUTTON_VARIANTS.DESTRUCTIVE}
                    >
                        <span>{strings.deleteEvent.deleteButton}</span>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DeleteEventInputs;