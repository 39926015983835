import { useState } from "react";
import { useAppContext } from "../../../contexts/AppContext";
import strings from "../../../i18n/i18nStrings";
import DatePicker from "../../../ui/datePicker/DatePicker";
import Dropdown from "../../../ui/dropdown/Dropdown";
import { EDUCATION_OPTIONS, GENDER_OPTIONS, ORIENTATION_OPTIONS, RACE_OPTIONS } from "../../common/constants/demographics.constants";
import { FILTER_TAGS } from "../../filters/filters.constants";
import Button, { BUTTON_VARIANTS } from "../../../ui/button/Button";
import styles from "./ProfileInputs.module.scss";

const ProfileInputs = ({
    onClose,
    isSettingsPage
}: {
    onClose?: () => void;
    isSettingsPage?: boolean;
}) => {
    const {
        userInfo,
        completeProfileDemographics
    } = useAppContext();

    const [newUserInfo, setNewUserInfo] = useState({ ...userInfo });

    const onUpdateUserInfo = (key: string, value: any) => {
        setNewUserInfo({
            ...newUserInfo,
            [key]: value
        })
    }

    return (
        <div>
            {
                !isSettingsPage && (
                    <>
                        <p className={styles.title}>{strings.userProfileScreen.title}</p>
                        <p>{strings.userProfileScreen.ask}</p>
                        <p>{strings.userProfileScreen.optional}</p>
                    </>
                )
            }
            <div className={styles.profileInputs}>
                <Dropdown
                    label={strings.userProfileScreen.interests}
                    options={Object.values(FILTER_TAGS)}
                    onSelect={(updatedInterests) => onUpdateUserInfo('interests', updatedInterests)}
                    setOptions={newUserInfo.interests}
                    multiSelect
                />
                <DatePicker
                    initialValue={new Date(newUserInfo.birthday)}
                    onChange={(updatedBirthday) => onUpdateUserInfo('birthday', updatedBirthday)}
                    label={strings.userProfileScreen.birthday}
                    type="date"
                />
                {/* DEMOGRAPHICS */}
                <Dropdown
                    label={strings.userProfileScreen.race}
                    options={Object.values(RACE_OPTIONS)}
                    onSelect={(updatedRace) => onUpdateUserInfo('race', updatedRace)}
                    setOptions={newUserInfo.race}
                    multiSelect
                />
                <Dropdown
                    label={strings.userProfileScreen.gender}
                    options={Object.values(GENDER_OPTIONS)}
                    onSelect={(updatedGender) => onUpdateUserInfo('gender', updatedGender)}
                    setOptions={newUserInfo.gender}
                />
                <Dropdown
                    label={strings.userProfileScreen.sexualOrientation}
                    options={Object.values(ORIENTATION_OPTIONS)}
                    onSelect={(updatedOrientation) => onUpdateUserInfo('orientation', updatedOrientation)}
                    setOptions={newUserInfo.orientation}
                />
                <Dropdown
                    label={strings.userProfileScreen.educationLevel}
                    options={Object.values(EDUCATION_OPTIONS)}
                    onSelect={(updatedEducation) => onUpdateUserInfo('education', updatedEducation)}
                    setOptions={newUserInfo.education}
                />

                {/*
                // TODO: Add later when there's actual use for user chat
                <PhotoUpload
                    uploadedPhotos={editedEvent.images}
                    onUpload={(updatedImages) => onUpdateUserInfo('images', updatedImages)}
                />
                <Input
                    label={strings.userProfileScreen.userName}
                    text={userName}
                    onChange={setUserName}
                />
                <Input
                    label={strings.userProfileScreen.description}
                    text={description}
                    onChange={setDescription}
                    numberOfLines={5}
                />
            */}
                <div className={styles.respondToButtonContainer}>
                    <div className={styles.buttonContainer}>
                        {
                            onClose && (
                                <Button
                                    text={strings.common.remindMe}
                                    onPress={onClose}
                                    roleVariant={BUTTON_VARIANTS.SECONDARY}
                                />
                            )
                        }
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            text={strings.common[isSettingsPage ? 'updateProfile' : 'completeProfile']}
                            onPress={() => completeProfileDemographics(newUserInfo)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileInputs;