import { useEffect, useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useEventsContext } from "../../contexts/EventsContext";
import strings from "../../i18n/i18nStrings";
import { EventType } from "../../types/EventType";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import styles from "./RSVPButton.module.scss";

const RSVPButton = ({
    eventData
}: {
    eventData: EventType
}) => {
    const { joinOrLeaveEvent } = useEventsContext();
    const { userInfo, isSignedIn } = useAppContext();

    const [isAttending, setIsAttending] = useState(false);

    useEffect(() => {
        const userEvents = userInfo?.events;

        if (isSignedIn && userEvents) {
            setIsAttending(!!userEvents.attending.find(eventId => eventId === eventData._id));
        }
    }, [eventData, isSignedIn, userInfo?.events]);

    const onRSVP = () => {
        joinOrLeaveEvent(eventData._id);
    }

    return isSignedIn && new Date().getTime() < eventData.endDateTime ? (
        <div className={styles.rsvpContainer}>
            <Button
                roleVariant={isAttending ? BUTTON_VARIANTS.DESTRUCTIVE : BUTTON_VARIANTS.CTA}
                onPress={onRSVP}
            >
                <span>
                    {
                        isAttending ?
                            strings.eventDetailsScreen.leave :
                            strings.eventDetailsScreen.join
                    }
                </span>
            </Button>
        </div>
    ) : null;
}

export default RSVPButton;