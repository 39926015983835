import { useNavigate } from 'react-router-dom';
import { SignedIn, SignedOut, SignInButton } from '@clerk/clerk-react';
import { ReactComponent as LogoIcon } from '../../assets/icons/LogoIcon.svg';
import { LAMP_POST_COLORS } from '../../ui/constants';
import { NAVIGATION_IDS } from '../common/constants/navigation';
import styles from './Header.module.scss';
import Button from '../../ui/button/Button';
import strings from '../../i18n/i18nStrings';
import NavMenu from '../navMenu/NavMenu';
import NavList from '../navList/NavList';
import { useNavContext } from '../../contexts/NavContext';

const Header = () => {
    const navigate = useNavigate();
    const { isNavMenuOpen } = useNavContext();

    return (
        <div className={styles.headerContainer}>
            <div className={styles.header}>
                <div
                    onClick={() => navigate(NAVIGATION_IDS.FRONTPAGE)}
                    className={styles.logo}
                >
                    <LogoIcon className={styles.logoImage} fill={LAMP_POST_COLORS.amber} />
                </div>
                <div
                    onClick={() => navigate(NAVIGATION_IDS.FRONTPAGE)}
                    className={styles.title}
                >
                    {strings.common.lampPost}
                </div>
                <div className={styles.menuContainer}>
                    <SignedOut>
                        <SignInButton>
                            <div className={styles.loginButtonContainer}>
                                <Button onPress={() => { }}>
                                    <span>{strings.landingScreen.login}</span>
                                </Button>
                            </div>
                        </SignInButton>
                    </SignedOut>
                    <SignedIn>
                        <div className={styles.navMenuButtonContainer}>
                            <NavMenu />
                        </div>
                    </SignedIn>
                </div>
            </div>
            {
                isNavMenuOpen && (
                    <div className={styles.mobileNav}>
                        <NavList />
                    </div>
                )
            }
        </div>
    )
}

export default Header;