
import strings from "../../i18n/i18nStrings";
import Modal from "../../ui/modal/Modal";
import BackHeader from "../mobile/backHeader/BackHeader";
import styles from "./DeleteUser.module.scss";
import DeleteUserInputs from "./inputs/DeleteUserInputs";

const DeleteUser = ({
    showDeleteUser,
    onClose,
}: {
    showDeleteUser: boolean;
    onClose: () => void;
}) => {

    return (
        <>
            {
                showDeleteUser && (
                    <div className={styles.deleteUserMobile}>
                        <>
                            <BackHeader
                                onBack={onClose}
                                backText={strings.eventDetailsScreen.backtoEvent}
                            />
                            <div className={styles.deleteForm}>
                                <DeleteUserInputs onClose={onClose} />
                            </div>
                        </>
                    </div>
                )
            }
            <Modal
                isOpen={showDeleteUser}
                onClose={onClose}
                isThin
            >
                <DeleteUserInputs onClose={onClose} />
            </Modal>
        </>
    )
}

export default DeleteUser;