import strings from "../../i18n/i18nStrings";
import styles from "./CreateOrEditGroup.module.scss";
import { GroupType } from "../../types/GroupType";
import BackHeader from "../mobile/backHeader/BackHeader";
import CreateOrEditGroupInputs from "./input/CreateOrEditGroupInputs";
import Modal from "../../ui/modal/Modal";
import { LAMPPOST_MOBILE_BREAKPOINTS } from "../../ui/constants";

const CreateOrEditGroup = ({
    showGroup,
    onBack,
    groupInfo,
}: {
    showGroup: boolean;
    onBack: () => void;
    groupInfo?: GroupType;
}) => (
    <>
        {
            showGroup && (
                <div className={styles.modifyGroupMobile}>
                    <>
                        <BackHeader
                            onBack={onBack}
                            backText={
                                window.innerWidth >= LAMPPOST_MOBILE_BREAKPOINTS.desktop || !groupInfo ?
                                    strings.groupScreen.backToGroups :
                                    strings.groupScreen.backToGroup(groupInfo.name)
                            }
                        />
                        <div className={styles.modifyForm}>
                            <CreateOrEditGroupInputs
                                onBack={onBack}
                                groupInfo={groupInfo}
                            />
                        </div>
                    </>
                </div>
            )
        }
        <Modal
            isOpen={showGroup}
            onClose={onBack}
        >
            <CreateOrEditGroupInputs
                onBack={onBack}
                groupInfo={groupInfo}
            />
        </Modal>
    </>
)

export default CreateOrEditGroup;