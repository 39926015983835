import PageLayout from "../common/PageLayout";
import { NAV_LIST_DATA } from "../../components/common/constants/navigation";
import styles from "./DashboardPage.module.scss";
import Card from "../../components/card/Card";

const DashboardPage = () => (
    <PageLayout>
        <div className={styles.dashboardContainer}>
            <div className={styles.cardContainer}>
                {
                    Object.values(NAV_LIST_DATA).map(pageInfo => (
                        <Card
                            key={pageInfo.url}
                            pageInfo={pageInfo}
                        />
                    ))
                }
            </div>
        </div>
    </PageLayout>
);

export default DashboardPage;