const strings = {
    admin: {
        eventsModeration: {
            title: 'Events Moderation',
            name: 'Name',
            creator: 'Creator Id'
        }
    }
}

export default strings;