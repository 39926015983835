import styles from "./SettingsPage.module.scss";
import PageLayout from "../common/PageLayout";
import ProfileInputs from "../../components/profileInfoModule/inputs/ProfileInputs";
import strings from "../../i18n/i18nStrings";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import { useState } from "react";
import DeleteUser from "../../components/deleteUser/DeleteUser";

const SettingsPage = () => {
    const [isDeletingUser, setIsDeletingUser] = useState(false);

    return (
        <PageLayout>
            <div className={styles.settingsContainer}>
                <div className={styles.settingInputsContainer}>
                    <div className={`${styles.settingInputs} ${isDeletingUser ? styles.isDeletingUser : ''}`}>
                        <div className={styles.sectionContainer}>
                            <p className={styles.title}>{strings.settingsScreen.personalize}</p>
                            <ProfileInputs isSettingsPage />
                        </div>
                        <div className={styles.sectionContainer}>
                            <p className={styles.title}>{strings.settingsScreen.accountManagement}</p>
                            <div className={styles.deleteAccountButton}>
                                <div />
                                <Button
                                    roleVariant={BUTTON_VARIANTS.DESTRUCTIVE}
                                    onPress={() => setIsDeletingUser(true)}
                                >
                                    <span>{strings.deleteUser.deleteButton}</span>
                                </Button>
                            </div>
                        </div>
                        {/* TODO: Add email notifications 
                            <p className={styles.title}>{strings.settingsScreen.emailNotifications}</p>
                        */}
                    </div>
                    {
                        isDeletingUser && (
                            <DeleteUser
                                showDeleteUser={isDeletingUser}
                                onClose={() => setIsDeletingUser(false)}
                            />
                        )
                    }
                </div>
            </div>
        </PageLayout>
    );
}

export default SettingsPage;