import Button, { BUTTON_VARIANTS } from "../../../ui/button/Button";
import styles from "./CloseButton.module.scss";

const CloseButton = ({ onClose }: { onClose: () => void }) => (
    <div className={styles.close} onClick={onClose}>
        <Button
            roleVariant={BUTTON_VARIANTS.GHOST}
            onPress={onClose}
        >
            <span>X</span>
        </Button>
    </div>
);

export default CloseButton;