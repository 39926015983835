import EventModeration from "../../components/eventModeration/EventModeration";
// import UserTable from "../../components/userTable/UserTable";
import PageLayout from "../common/PageLayout";
import styles from "./AdminPage.module.scss";

const AdminPage = () => {

    return (
        <PageLayout>
            <div className={styles.contentContainer}>
                <EventModeration />
                {/*
                    TODO: Add table to search and update users
                    <UserTable />
                */}
            </div>
        </PageLayout>
    )
}

export default AdminPage;