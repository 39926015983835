import { createContext, useContext, useEffect, useState } from 'react';
import { DEFAULT_HEADERS, lampPostFetch } from '../utils/fetchHelpers';
import {
    ACCEPT_GROUP,
    CREATE_GROUP,
    DECLINE_GROUP,
    DELETE_GROUP,
    GET_GROUPS,
    LEAVE_GROUP,
    UPDATE_GROUP,
} from '../components/common/constants/endpoints';
import { GroupType } from '../types/GroupType';
import { useAppContext } from './AppContext';

type GroupsByType = {
    created: GroupType[];
    member: GroupType[];
    pending?: GroupType[];
}

type LoadingByType = {
    created: boolean;
    member: boolean;
}

type GroupContextValues = {
    groups: GroupsByType;
    loadingGroups: LoadingByType;
    createOrEditGroup: (groupInfo: GroupType, onFinish: () => void) => void;
    deleteGroup: (groupId: string, callback?: () => void) => void;
    leaveGroup: (groupId: string, callback?: () => void) => void;
    declineGroup: (groupId: string, callback?: () => void) => void;
    acceptGroup: (groupId: string, callback?: () => void) => void;
}

export const GroupContext = createContext({} as GroupContextValues);

export const useGroupContext = () => useContext(GroupContext);

export const GroupContextProvider = ({ children }) => {
    const {
        userInfo,
        getUpdatedUser,
        server
    } = useAppContext();

    const [groups, setGroups] = useState<GroupsByType>({
        created: [],
        member: []
    });

    const [loadingGroups, setLoadingGroups] = useState({
        created: false,
        member: false,
    });

    const onGroupsFetch = (
        fetchedGroups: string[],
        onSuccess: (fetchedEventsData: GroupType[]) => void
    ) => {
        Promise.all(fetchedGroups?.map(id =>
            fetch(`${server}${GET_GROUPS(id)}`, {
                method: "GET",
                headers: DEFAULT_HEADERS
            })
                .then(resp => resp.json())
        )).then(results => {
            onSuccess(results);
        });
    }

    useEffect(() => {
        (async () => {
            if (userInfo?.userGroups) {
                setLoadingGroups({
                    created: true,
                    member: true,
                });

                // Get each type of event on the user and separate the data accordingly
                Object.keys(userInfo.userGroups)
                    .filter((userGroupType) => Array.isArray(userInfo.userGroups[userGroupType]))
                    .forEach((userGroupType) => {
                        onGroupsFetch(
                            userInfo.userGroups[userGroupType],
                            (fetchedEventsData) => {
                                setLoadingGroups(prevLoading => ({
                                    ...prevLoading,
                                    [userGroupType]: false,
                                }));

                                setGroups(prevLoading => ({
                                    ...prevLoading,
                                    [userGroupType]: fetchedEventsData,
                                }));
                            }
                        );
                    })
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, server]);

    const createOrEditGroup = (
        groupInfo: GroupType,
        onFinish: () => void
    ) => {
        const fetchOptions: RequestInit = {
            headers: { "Content-type": "application/json", },
            method: groupInfo._id ? "PATCH" : "POST",
            body: JSON.stringify({
                ...groupInfo,
                groupLeader: groupInfo._id ? groupInfo.groupLeader : userInfo._id,
            })
        };

        lampPostFetch(
            groupInfo._id ? UPDATE_GROUP(groupInfo._id) : CREATE_GROUP,
            fetchOptions,
            () => {
                getUpdatedUser();
                onFinish();
            }
        );
    }

    const deleteGroup = (groupId: string, callback: () => void) => {
        lampPostFetch(
            DELETE_GROUP(groupId),
            { method: "DELETE" },
            () => {
                callback();
                getUpdatedUser();
            }
        );
    }

    const leaveGroup = (groupId: string, callback: () => void) => {
        lampPostFetch(
            LEAVE_GROUP(groupId, userInfo._id),
            { method: "PATCH" },
            () => {
                callback();
                getUpdatedUser();
            }
        );
    }

    const declineGroup = (groupId: string, callback: () => void) => {
        lampPostFetch(
            DECLINE_GROUP(groupId, userInfo._id, userInfo.email),
            { method: "PATCH" },
            () => {
                callback();
                getUpdatedUser();
            }
        );
    }

    const acceptGroup = (groupId: string, callback: () => void) => {
        lampPostFetch(
            ACCEPT_GROUP(groupId, userInfo._id, userInfo.email),
            { method: "PATCH" },
            () => {
                callback();
                getUpdatedUser();
            }
        );
    }

    return (
        <GroupContext.Provider
            value={{
                groups,
                loadingGroups,
                createOrEditGroup,
                deleteGroup,
                leaveGroup,
                declineGroup,
                acceptGroup,
            }}
        >
            {children}
        </GroupContext.Provider>
    )
}