import strings from "../../i18n/i18nStrings";
import { GroupType } from "../../types/GroupType";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import BackHeader from "../mobile/backHeader/BackHeader";
import styles from "./GroupViewMobile.module.scss";

const GroupViewMobile = ({
    selectedGroup,
    groupType,
    onPrimaryButtonSelect,
    onSecondaryButtonSelect,
    onClose
}: {
    selectedGroup: GroupType
    groupType: { isGroupMember: boolean, isPendingMember: boolean }
    onPrimaryButtonSelect: (isPendingMember: boolean, group: GroupType) => void;
    onSecondaryButtonSelect: (isPendingMember: boolean, isGroupMember: boolean, group: GroupType) => void;
    onClose: () => void;
}) => (
    <div className={styles.groupViewContainer}>
        <BackHeader
            onBack={onClose}
            backText={strings.groupScreen.backToGroups}
        />
        <div className={styles.groupViewInfo}>
            <div className={styles.title}>{selectedGroup.name}</div>
            <div>
                <div className={styles.header}>
                    {strings.groupScreen.groupTable.groupLeader}
                </div>
                <div className={styles.content}>
                    {
                        typeof selectedGroup.groupLeader === 'string' ?
                            selectedGroup.groupLeader :
                            selectedGroup.groupLeader.name || selectedGroup.groupLeader.email
                    }
                </div>
            </div>
            <div>
                <div className={styles.header}>
                    {strings.groupScreen.groupTable.groupMembers}
                </div>
                <div className={styles.content}>
                    {selectedGroup.members.map(member => (
                        <div>
                            {member.name || member.email}
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <div className={styles.header}>
                    {strings.groupScreen.groupTable.pendingInvites}
                </div>
                <div className={styles.content}>
                    {selectedGroup.pending.map(pendingMember => (
                        <div>
                            {pendingMember.name || pendingMember.email}
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className={styles.buttonContainer}>
            {
                groupType.isGroupMember ? null : (
                    <Button onPress={() => onPrimaryButtonSelect(groupType.isPendingMember, selectedGroup)}>
                        <span>
                            {
                                groupType.isPendingMember ?
                                    strings.common.accept :
                                    strings.common.edit
                            }
                        </span>
                    </Button>
                )
            }
            <Button
                roleVariant={BUTTON_VARIANTS.DESTRUCTIVE}
                onPress={() => onSecondaryButtonSelect(groupType.isPendingMember, groupType.isGroupMember, selectedGroup)}
            >
                <span>
                    {groupType.isPendingMember ? strings.common.decline : ''}
                    {groupType.isGroupMember ? strings.common.leave : ''}
                    {!groupType.isPendingMember && !groupType.isGroupMember ? strings.common.delete : ''}
                </span>
            </Button>
        </div>
    </div>
)

export default GroupViewMobile;