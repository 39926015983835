// User Endpoints
export const CREATE_USER = "/user/createUser";
export const GET_USER = (email: string, password: string) => `/user/findUser/${email}/${password}`;
export const GET_USER_BY_ID = (userId: string) => `/user/getUserById/${userId}`;
export const GET_SELF = (userId: string) => `/user/getSelf/${userId}`;
export const UPDATE_USER = (userId: string) => `/user/updateUser/${userId}`;
export const LOGIN_USER = (userId: string, emailAddress: string) => `/user/login/${userId}/${emailAddress ? `${emailAddress}` : ''}`;
export const GET_USER_BY_EMAIL = (email: string) => `/user/getUserByEmail/${email}`;
export const GET_USERS_BY_EMAIL = (email: string) => `/user/getUsersByEmail/${email}`;
export const DELETE_USER = (userId: string) => `/user/delete/${userId}`;

// Event Endpoints
export const CREATE_EVENT = "/event/createEvent";
export const SHARE_EVENT = "/event/shareEvent";
export const GET_EVENT = (eventId: string) => `/event/getEvent/${eventId}`;
export const GET_EVENTS = "/event/getEvents";
export const GET_MODERATION_EVENTS = "/event/getModerationRequiredEvents";
export const UPDATE_EVENT = (eventId: string) => `/event/updateEvent/${eventId}`;
export const DELETE_EVENT = (eventId: string) => `/event/delete/${eventId}`;
export const JOIN_EVENT = (eventId: string, userId: string) => `/event/attendEvent/${eventId}/${userId}`;
export const ACCEPT_MODERATED_EVENT = (eventId: string) => `/event/acceptModeratedEvent/${eventId}`;

// Group Endpoints
export const CREATE_GROUP = "/group/createGroup";
export const GET_GROUPS = (groupId: string) => `/group/findGroup/${groupId}`;
export const UPDATE_GROUP = (groupId: string) => `/group/updateGroup/${groupId}`;
export const DELETE_GROUP = (groupId: string) => `/group/deleteGroup/${groupId}`;
export const LEAVE_GROUP = (groupId: string, userId: string) => `/group/leaveGroup/${groupId}/${userId}`;
export const GET_GROUP_MEMBERS = (groupId: string) => `/group/getMembers/${groupId}`;
export const DECLINE_GROUP = (groupId: string, userId: string, userEmail: string) => `/group/declineGroup/${groupId}/${userId}/${userEmail}`;
export const ACCEPT_GROUP = (groupId: string, userId: string, userEmail: string) => `/group/acceptGroup/${groupId}/${userId}/${userEmail}`;

// Message Endpoints
export const CREATE_MESSENGER = "/messenger/createMessenger";
export const SEND_MESSAGE = (messengerId: string) => `/messenger/sendMessage/${messengerId}`;
export const INITIATE_MESSENGER = (userId: string) => `/messenger/initiateMessenger/${userId}`;
export const GET_MESSENGER_BY_ID = (userId: string, messengerId: string) => `/messenger/getMessenger/${userId}/${messengerId}`;
export const GET_ALL_MESSENGERS = (userId: string) => `/messenger/getAllMessengers/${userId}`;
export const DELETE_MESSENGER = (chatId: string) => `/messenger/deleteMessenger/${chatId}`;