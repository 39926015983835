import { EventType } from "../types/EventType";

export const formatAddress = (eventData: EventType) => {
    const address = eventData.location.address || eventData.location.formatted_address;
    const splitAddress = address.split(',');

    const separatedAddress = [
        splitAddress[0],
        splitAddress[1],
        splitAddress[2],
    ];

    if (parseFloat(eventData.distance) > 0) {
        separatedAddress.push(`(${eventData.distance})`)
    }

    return separatedAddress;
}