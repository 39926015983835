import { useState } from "react";
import strings from "../../../i18n/i18nStrings";
import Button, { BUTTON_VARIANTS } from "../../../ui/button/Button";
import Input from "../../../ui/input/Input";
import styles from "./ShareEventInputs.module.scss";
import { useEventsContext } from "../../../contexts/EventsContext";

const ShareEventInputs = ({
    onClose
}: {
    onClose: () => void;
}) => {
    const { shareEvent } = useEventsContext();

    const [groupMembers, setGroupMembers] = useState('');

    const onSendEmail = () => {
        const recipientEmails = groupMembers.replace(/\s/g, "").split(',');

        if (recipientEmails.length) {
            shareEvent(recipientEmails);
            onClose();
        }
    }

    return (
        <div>
            <div className={styles.shareEventInputs}>
                <Input
                    label={strings.eventDetailsScreen.shareLabel}
                    text={groupMembers}
                    onChange={setGroupMembers}
                    numberOfLines={5}
                />
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    onPress={onClose}
                    roleVariant={BUTTON_VARIANTS.SECONDARY}
                >
                    <span>{strings.common.cancel}</span>
                </Button>
                <Button
                    onPress={onSendEmail}
                >
                    <span>{strings.common.send}</span>
                </Button>
            </div>
        </div>
    )
}

export default ShareEventInputs;