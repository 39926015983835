import { ReactElement } from "react";
import { createPortal } from "react-dom";
import styles from "./Modal.module.scss";
import CloseButton from "../../components/common/closeButton/CloseButton";

const Modal = ({
    isOpen,
    onClose,
    isThin,
    children
}: {
    isOpen: boolean;
    onClose: () => void;
    isThin?: boolean;
    children: ReactElement
}) => {
    const modalRoot = document.getElementById('root');

    if (!isOpen || !modalRoot) {
        return <div />;
    }

    return createPortal(
        <div className={styles.modal}>
            <div className={styles.modalBackground} onClick={onClose} />
            <div className={`${styles.modalContents}  ${isThin ? styles.thin : ''}`}>
                <CloseButton onClose={onClose} />
                {children}
            </div>
        </div>,
        modalRoot
    );
}

export default Modal;