import { SignOutButton } from "@clerk/clerk-react";
import { NAV_LIST_DATA } from "../common/constants/navigation";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import strings from "../../i18n/i18nStrings";
import styles from "./NavList.module.scss";
import { useNavContext } from "../../contexts/NavContext";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";

const NavList = () => {
    const navigate = useNavigate();
    const { userInfo } = useAppContext();
    const { setIsNavMenuOpen } = useNavContext();

    const onNavigation = (url: string) => {
        setIsNavMenuOpen(false);
        navigate(`/${url}`);
    }

    return (
        <div className={styles.navList}>
            {
                Object.values(NAV_LIST_DATA)
                    .filter(navListItem => navListItem.userPermissionCheck(userInfo))
                    .map(navListItem => (
                        <div
                            key={navListItem.url}
                            onClick={() => onNavigation(navListItem.url)}
                            className={styles.menuItem}
                        >
                            <span>{navListItem.title}</span>
                        </div>
                    ))
            }
            <SignOutButton>
                <div className={styles.logoutButtonContainer}>
                    <Button
                        onPress={() => { }}
                        roleVariant={BUTTON_VARIANTS.DESTRUCTIVE}
                    >
                        <span>{strings.common.logOut}</span>
                    </Button>
                </div>
            </SignOutButton>
        </div>
    )
}

export default NavList;