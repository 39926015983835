import { useEffect, useState } from 'react';
import Input from '../../../ui/input/Input';
import strings from '../../../i18n/i18nStrings';
import Dropdown from '../../../ui/dropdown/Dropdown';
import { FILTER_AGES, FILTER_COSTS, FILTER_DISTANCE, FILTER_EVENT_TYPES, FILTER_TAGS } from '../filters.constants';
import { OptionType } from '../../../ui/constants';
import { InterestType } from '../../../types/InterestType';
import { LocationType } from '../../../types/LocationType';
import Button, { BUTTON_VARIANTS } from '../../../ui/button/Button';
import styles from "./FiltersInputs.module.scss";
import { useFilterContext } from '../../../contexts/FilterContext';
import CloseButton from '../../common/closeButton/CloseButton';
import LocationAutocomplete from '../../../ui/locationAutocomplete/LocationAutocomplete';
import { SignedIn } from '@clerk/clerk-react';
import { useGroupContext } from '../../../contexts/GroupContext';
import { useEventsContext } from '../../../contexts/EventsContext';

const FiltersInputs = () => {
    const { groups } = useGroupContext();
    const { onDeselectEvent } = useEventsContext();

    const {
        filters,
        onSubmitFilters,
        onClearFilters,
        onCloseFilters
    } = useFilterContext();

    const [newFilters, setNewFilters] = useState({ ...filters });
    const [groupsJoined, setGroupsJoined] = useState([]);

    useEffect(() => {
        setGroupsJoined([...groups?.created, ...groups?.member].sort((groupA, groupB) => {
            if (groupA.name > groupB.name) {
                return -1;
            }

            if (groupA.name > groupB.name) {
                return 1;
            }

            return 0;
        })
            .map((group) => ({
                ...group,
                id: group._id
            }))
        )
    }, [groups?.created, groups?.member]);

    const changeFilter = (
        key: string,
        value: string | number | OptionType[] | InterestType[] | LocationType
    ) => {
        setNewFilters(prevFilters => ({
            ...prevFilters,
            [key]: value
        }))
    }

    const onSubmitNewFilters = () => {
        onDeselectEvent();
        onSubmitFilters(newFilters);
    }

    return (
        <div className={styles.filters}>
            <CloseButton onClose={onCloseFilters} />
            <div className={styles.filterInputs}>
                <SignedIn>
                    <Dropdown
                        label={strings.filters.types.eventTypes}
                        options={Object.values(FILTER_EVENT_TYPES).map(({ id, name }) => ({ id, name }))}
                        onSelect={(newEventType) => changeFilter('eventType', newEventType)}
                        setOptions={newFilters.eventType}
                    />
                </SignedIn>
                {
                    newFilters.eventType[0].id === FILTER_EVENT_TYPES.allEvents.id && (
                        <>
                            <Input
                                text={newFilters.name}
                                onChange={(name) => changeFilter('name', name)}
                                label={strings.filters.types.name}
                            />
                            <LocationAutocomplete
                                initialLocation={newFilters.location}
                                onChange={(newLocation) => changeFilter('location', newLocation)}
                            />
                            <Dropdown
                                label={strings.filters.types.distance}
                                options={Object.values(FILTER_DISTANCE).map(({ id, name }) => ({ id, name }))}
                                onSelect={(distance) => changeFilter('distance', distance)}
                                setOptions={newFilters.distance}
                            />
                            <Dropdown
                                label={strings.filters.types.cost}
                                options={Object.values(FILTER_COSTS).map(({ id, name }) => ({ id, name }))}
                                onSelect={(cost) => changeFilter('cost', cost)}
                                setOptions={newFilters.cost}
                            />
                            <Dropdown
                                label={strings.filters.types.tags}
                                options={Object.values(FILTER_TAGS)}
                                onSelect={(tags) => changeFilter('tags', tags)}
                                setOptions={newFilters.tags}
                                multiSelect
                            />
                            <Dropdown
                                label={strings.filters.types.ages}
                                options={Object.values(FILTER_AGES)}
                                onSelect={(ages) => changeFilter('ages', ages)}
                                setOptions={newFilters.ages}
                                multiSelect
                            />
                            {
                                groupsJoined?.length ? (
                                    <Dropdown
                                        label={strings.filters.types.groups}
                                        options={groupsJoined}
                                        onSelect={(group) => changeFilter('groups', group.map(groupInfo => groupInfo._id))}
                                        setOptions={
                                            groupsJoined.filter((groupJoined) => newFilters.groups.find((groupId) => groupJoined._id === groupId))
                                        }
                                        multiSelect
                                    />
                                ) : null
                            }
                        </>
                    )
                }
            </div>
            <div className={styles.respondToButtonContainer}>
                <div className={styles.buttonContainer}>
                    <Button
                        text={strings.common.clear}
                        onPress={onClearFilters}
                        roleVariant={BUTTON_VARIANTS.SECONDARY}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        text={strings.common.submitFilters}
                        onPress={onSubmitNewFilters}
                    />
                </div>
            </div>
        </div>
    )
}

export default FiltersInputs;