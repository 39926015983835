import { useRef, useState } from "react";
import { ReactComponent as ClosedEyeIcon } from '../../assets/icons/ClosedEyeIcon.svg';
import { ReactComponent as OpenEyeIcon } from '../../assets/icons/OpenEyeIcon.svg';
import styles from "./Input.module.scss";
import { LAMP_POST_COLORS } from "../constants";
import Label from "../label/Label";

const INPUT_SCRUB_REGEX = /(<\w([^>]+)\w>)/ig;

const Input = ({
    text = '',
    label = '',
    placeholder = '',
    disabled = false,
    maxLength = undefined,
    numberOfLines = 1,
    hideable = false,
    onChange,
    textContainerStyle,
}: {
    text?: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    maxLength?: number;
    numberOfLines?: number;
    hideable?: boolean;
    onChange: (text: string) => void;
    textContainerStyle?: any;
}) => {
    const inputRef = useRef<any>();
    const [isHidden, setIsHidden] = useState(hideable);

    return (
        <div>
            <div className={styles.textContainer}>
                {
                    label && <Label label={label} />
                }
                {
                    numberOfLines > 1 ? (
                        <textarea
                            onChange={(text) => onChange(text.currentTarget.value.replace(INPUT_SCRUB_REGEX, ''))}
                            value={text}
                            placeholder={placeholder}
                            rows={numberOfLines}
                            disabled={disabled}
                            maxLength={maxLength}
                            className={`${styles.textInput} ${styles.multiline}`}
                        />
                    ) : (
                        <input
                            type={isHidden ? "password" : "text"}
                            ref={inputRef}
                            onChange={(text) => onChange(text.currentTarget.value.replace(INPUT_SCRUB_REGEX, ''))}
                            value={text}
                            placeholder={placeholder}
                            disabled={disabled}
                            maxLength={maxLength}
                            className={styles.textInput}
                        />
                    )
                }
                {
                    hideable && (
                        <div
                            className={styles.eye}
                            onClick={() => setIsHidden(prevState => !prevState)}
                        >
                            {
                                isHidden ?
                                    <ClosedEyeIcon
                                        width={22}
                                        fill={LAMP_POST_COLORS.oliveGreen}
                                        onClick={() => inputRef.current.type = 'password'}
                                    /> :
                                    <OpenEyeIcon
                                        width={22}
                                        fill={LAMP_POST_COLORS.oliveGreen}
                                        onClick={() => inputRef.current.type = 'text'}
                                    />
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default Input;