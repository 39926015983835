import strings from "../i18n/i18nStrings";

export const getEventDates = (startDateTime: number, endDateTime: number) => {
    const startDateTimeData = new Date(startDateTime);
    const startMonth = (startDateTimeData.getMonth() + 1).toString();
    const startDay = startDateTimeData.getDate().toString();
    const startTime = startDateTimeData.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

    const endDateTimeData = new Date(endDateTime);
    const endMonth = (endDateTimeData.getMonth() + 1).toString();
    const endDay = endDateTimeData.getDate().toString();
    const endTime = endDateTimeData.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

    if (startMonth === endMonth && startDay === endDay) {
        return strings.common.sameDate(startDay, startMonth, startTime, endTime);
    }

    return strings.common.differentDate(startDay, startMonth, startTime, endDay, endMonth, endTime);
}