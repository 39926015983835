const createEventStrings = {
    createOrEditEventScreen: {
        createEvent: 'Create Event',
        editEvent: 'Edit Event',
        eventName: 'Name',
        eventHost: 'Event Host',
        eventLocation: 'Location',
        eventStartDateTime: 'Start Date / Time',
        eventEndDateTime: 'End Date / Time',
        eventCost: 'Cost',
        linkToTickets: 'Link to Tickets',
        ticketLinkError: 'Please enter a valid url (ex: www.lamppost.app)',
        eventDescription: 'Description (min. 5, max. 250)',
        eventTags: 'Tags',
        groups: 'Allowed Groups',
        eventAges: 'Ages',
        eventRepeating: 'Repeats',
        attendingEvent: 'Are you attending?',
        eventPlaceholder: 'Type a location',
        allowedEmails: 'Whitelisted Users (separate emails with a comma)',
        advancedOptions: 'Advanced Options'
    }
}

export default createEventStrings;