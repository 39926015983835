import { useAppContext } from "../../contexts/AppContext";
import Modal from "../../ui/modal/Modal";
import ProfileInputs from "./inputs/ProfileInputs";
import styles from "./ProfileInfoModule.module.scss";

const ProfileInfoModule = () => {
    const {
        userInfo,
        showCompleteProfileModule,
        closeCompleteProfileModule
    } = useAppContext();

    return !!userInfo && !userInfo?.isAdmin && showCompleteProfileModule ? (
        <>
            <div className={styles.mobileContainer}>
                <ProfileInputs onClose={closeCompleteProfileModule} />
            </div>
            <Modal
                isOpen={showCompleteProfileModule}
                onClose={closeCompleteProfileModule}
            >
                <ProfileInputs onClose={closeCompleteProfileModule} />
            </Modal>
        </>
    ) : null;
}

export default ProfileInfoModule;