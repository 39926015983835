import {
    Route,
    Navigate,
    Routes,
    BrowserRouter
} from 'react-router-dom';
import HomePage from '../homePage/HomePage';
import DashboardPage from '../dashboardPage/DashboardPage';
import { useAppContext } from '../../contexts/AppContext';
import EventsPage from '../eventsPage/EventsPage';
import { NAVIGATION_IDS } from '../../components/common/constants/navigation';
import AboutPage from '../about/AboutPage';
import FaqPage from '../faq/FaqPage';
import LegalPage from '../legal/LegalPage';
import GroupsPage from '../groupsPage/GroupsPage';
import SettingsPage from '../settingsPage/SettingsPage';
import { GroupContextProvider } from '../../contexts/GroupContext';
import { EventsContextProvider } from '../../contexts/EventsContext';
import { FilterContextProvider } from '../../contexts/FilterContext';
import AdminPage from '../adminPage/AdminPage';
// import BlogPage from '../blog/BlogPage';
import { NavContextProvider } from '../../contexts/NavContext';

const Navigation = () => {
    const { isSignedIn } = useAppContext();

    return (
        <BrowserRouter>
            <NavContextProvider>
                <GroupContextProvider>
                    <FilterContextProvider>
                        <EventsContextProvider>
                            <Routes>
                                <Route
                                    index
                                    element={<Navigate to={NAVIGATION_IDS.FRONTPAGE} replace />}
                                />
                                <Route
                                    path={NAVIGATION_IDS.FRONTPAGE}
                                    element={<HomePage />}
                                />
                                <Route
                                    path={NAVIGATION_IDS.ABOUT}
                                    element={<AboutPage />}
                                />
                                {/* <Route
                                    path={NAVIGATION_IDS.BLOG}
                                    element={<BlogPage />}
                                /> */}
                                <Route
                                    path={NAVIGATION_IDS.FAQ}
                                    element={<FaqPage />}
                                />
                                <Route
                                    path={NAVIGATION_IDS.LEGAL}
                                    element={<LegalPage />}
                                />
                                {
                                    isSignedIn && (
                                        <>
                                            <Route
                                                path={NAVIGATION_IDS.DASHBOARD}
                                                element={<DashboardPage />}
                                            />
                                            <Route
                                                path={NAVIGATION_IDS.EVENTS}
                                                element={<EventsPage />}
                                            />
                                            <Route
                                                path={NAVIGATION_IDS.GROUPS}
                                                element={<GroupsPage />}
                                            />
                                            <Route
                                                path={NAVIGATION_IDS.SETTINGS}
                                                element={<SettingsPage />}
                                            />
                                            <Route
                                                path={NAVIGATION_IDS.ADMIN}
                                                element={<AdminPage />}
                                            />
                                        </>
                                    )
                                }
                                {/* <Route
                                path='*'
                                element={<Navigate to={NAVIGATION_IDS.FRONTPAGE} replace />}
                            /> */}
                            </Routes>
                        </EventsContextProvider>
                    </FilterContextProvider>
                </GroupContextProvider>
            </NavContextProvider>
        </BrowserRouter>
    )
}

export default Navigation;
