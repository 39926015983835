import { useEffect, useState } from "react";

// import styles from "./EventsPage.module.scss";
import PageLayout from "../common/PageLayout";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import strings from "../../i18n/i18nStrings";
import { useAppContext } from "../../contexts/AppContext";
import { DEFAULT_HEADERS } from "../../utils/fetchHelpers";
import { GET_EVENT } from "../../components/common/constants/endpoints";
import { EventType } from "@testing-library/react";
import Loader from "../../components/loader/Loader";
import CreateOrEditEvent from "../../components/createOrEditEvent/CreateOrEditEvent";

const EventsPage = () => {
    const {
        userInfo,
        server
    } = useAppContext();

    const [creatingEvent, setCreatingEvent] = useState(false);
    // TODO: Combine this with the item above, just pass the id or something in the context
    // const [editingEvent, setEditingEvent] = useState(null);

    const [events, setEvents] = useState({
        hosting: [],
        created: [],
        attending: [],
        previous: []
    });

    const [loadingEvents, setLoadingEvents] = useState({
        hosting: false,
        created: false,
        attending: false,
        previous: false,
    });

    const onEventsFetch = (
        fetchedEvents: EventType[],
        onSuccess: (fetchedEventsData: EventType[]) => void
    ) => {
        Promise.all(fetchedEvents.map(id =>
            fetch(`${server}${GET_EVENT(id)}`, {
                method: "GET",
                headers: DEFAULT_HEADERS
            })
                .then(resp => resp.json())
        )).then(results => {
            onSuccess(results);
        });
    }

    useEffect(() => {
        (async () => {
            setLoadingEvents({
                hosting: true,
                created: true,
                attending: true,
                previous: true,
            });

            // Get each type of event on the user and separate the data accordingly
            Object.keys(userInfo?.events).forEach((userEventType) => {
                onEventsFetch(
                    userInfo?.events[userEventType],
                    (fetchedEventsData) => {
                        setLoadingEvents(prevLoading => ({
                            ...prevLoading,
                            [userEventType]: false,
                        }));

                        setEvents(prevLoading => ({
                            ...prevLoading,
                            [userEventType]: fetchedEventsData,
                        }));
                    }
                );
            })
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, server])

    return (
        <PageLayout>
            <div>
                <div>
                    <Button
                        text={strings.homeScreen.createEvent}
                        roleVariant={BUTTON_VARIANTS.CTA}
                        onPress={() => setCreatingEvent(true)}
                    />
                </div>
                <div>
                    {
                        Object.keys(events).map((userEventType) => (
                            <div>
                                <div>
                                    {userEventType}
                                </div>
                                {
                                    loadingEvents[userEventType] ? (
                                        <Loader />
                                    ) : events[userEventType].map((event) => (
                                        <div>
                                            {event.name}
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
                <CreateOrEditEvent
                    showCreateEvent={creatingEvent}
                    onClose={() => setCreatingEvent(false)}
                />
            </div>
        </PageLayout>
    )
}

export default EventsPage;