import strings from "../../i18n/i18nStrings";
import PhotoRoll from "../photoRoll/PhotoRoll";
import styles from "./AdminEventPreview.module.scss";
import { EventType } from "../../types/EventType";
import { FILTER_TAGS } from "../filters/filters.constants";
import InterestChips from "../interestChips/InterestChips";
import { formatAddress } from "../../utils/formatAddress";
import Modal from "../../ui/modal/Modal";
import { ReactComponent as ChevronIcon } from '../../assets/icons/ChevronIcon.svg';
import { ReactComponent as ApproveIcon } from '../../assets/icons/CheckIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/XIcon.svg';
import { LAMP_POST_COLORS } from "../../ui/constants";
import { useEventsContext } from "../../contexts/EventsContext";

const AdminEventPreview = ({
    eventData,
    onClose,
    onNavToEvent,
    hasNext,
    hasPrev,
}: {
    eventData: EventType;
    onClose: () => void;
    onNavToEvent: (isNext?: boolean) => void;
    hasNext: boolean;
    hasPrev: boolean;
}) => {
    const {
        acceptModeratedEvent,
        deleteEvent
    } = useEventsContext();

    return eventData ? (
        <Modal
            isOpen={!!eventData}
            onClose={onClose}
        >
            <>
                <div className={styles.eventDisplayAndActionsContainer}>
                    <div className={styles.eventDisplayContainer}>
                        <div className={styles.eventPreviewContainer}>
                            <div className={styles.eventPreview}>
                                {
                                    eventData?.images?.length ? <PhotoRoll photoUrls={eventData.images} /> : null
                                }
                                <div className={styles.infoContainer}>
                                    <div className={styles.sizedInfo}>
                                        <div className={styles.infoItem}>
                                            <p className={styles.eventName}>{eventData.name}</p>
                                        </div>
                                        <div className={styles.infoItem}>
                                            <p className={styles.eventLocation}>{eventData.location.name}</p>
                                        </div>
                                        <div className={styles.infoItem}>
                                            {formatAddress(eventData).map((addressPiece, idx) => (
                                                <p
                                                    key={idx}
                                                    className={styles.locationText}
                                                >
                                                    {addressPiece}
                                                </p>
                                            ))}
                                        </div>
                                        <div className={styles.infoItem}>
                                            <p className={styles.infoItemText}>
                                                {
                                                    strings.common.datePicker(
                                                        new Date(eventData.startDateTime).toLocaleDateString(),
                                                        new Date(eventData.startDateTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })
                                                    )
                                                }
                                            </p>
                                            <p className={styles.infoItemText}>
                                                {
                                                    strings.common.datePicker(
                                                        new Date(eventData.endDateTime).toLocaleDateString(),
                                                        new Date(eventData.endDateTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })
                                                    )
                                                }
                                            </p>
                                        </div>
                                        <div className={`${styles.attendeeList} ${styles.infoItem}`}>
                                            <div className={styles.attendeeImagesContainer}>
                                                {/* {
                                // TODO: Readd when attendees make since to show
                                eventData.attendees.slice(0, 3).map((attendee: { attendeeImage: string | undefined; }, idx: Key | null | undefined) => (
                                    <div key={idx}>
                                        <img
                                            src={attendee.attendeeImage}
                                            className={styles.attendeeImage}
                                            alt="Attendee"
                                        />
                                    </div>
                                ))
                            } */}
                                            </div>
                                            {
                                                eventData.attendees.length > 3 ? (
                                                    <p className={styles.infoItemText}>
                                                        {strings.eventDetailsScreen.additionalAttendees(eventData.attendees.length - 3)}
                                                    </p>
                                                ) : null
                                            }
                                        </div>
                                        <div className={eventData?.tags?.length ? styles.infoItem : ''}>
                                            <p className={styles.infoItemText}>
                                                {strings.eventDetailsScreen.cost(
                                                    eventData.cost > 0 ? eventData.cost.toFixed(2) : strings.eventDetailsScreen.free
                                                )}
                                            </p>
                                        </div>
                                        {
                                            eventData?.tags?.length ? (
                                                <InterestChips
                                                    interests={eventData?.tags?.map((tagId: any) => Object.values(FILTER_TAGS).find(filterTag => filterTag.id === tagId))}
                                                />
                                            ) : null
                                        }
                                    </div>
                                </div>
                                <p>{eventData.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.navigation}>
                    {
                        hasPrev ? (
                            <div
                                className={styles.prev}
                                onClick={() => onNavToEvent(false)}
                            >
                                <ChevronIcon
                                    width={50}
                                    fill={LAMP_POST_COLORS.oliveGreen}
                                    className={styles.chevron}
                                />
                            </div>
                        ) : null
                    }
                    {
                        hasNext ? (
                            <div
                                className={styles.next}
                                onClick={() => onNavToEvent(true)}
                            >
                                <ChevronIcon
                                    width={50}
                                    fill={LAMP_POST_COLORS.oliveGreen}
                                    className={styles.chevron}
                                />
                            </div>
                        ) : null
                    }
                </div>
                <div className={styles.actions}>
                    <div
                        className={styles.approve}
                        onClick={() => acceptModeratedEvent(eventData._id)}
                    >
                        <div className={styles.icon}>
                            <ApproveIcon />
                        </div>
                        <span>{strings.common.accept}</span>
                    </div>
                    <div
                        className={styles.decline}
                        onClick={() => deleteEvent(eventData._id, true)}
                    >
                        <div className={styles.icon}>
                            <DeleteIcon />
                        </div>
                        <span>{strings.common.decline}</span>
                    </div>
                </div>
            </>
        </Modal>
    ) : null;
}

export default AdminEventPreview;