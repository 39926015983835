const groupStrings = {
    groupScreen: {
        createGroup: 'Create New Group',
        editGroup: 'Edit Group',
        groupName: 'Group Name',
        groupLeader: 'Group Leader',
        groupMembers: 'Group Members (separate emails with a comma)',
        backToGroups: 'Back to Groups',
        backToGroup: (name: string) => `Back to ${name}`,
        pendingInvites: 'Pending Invites',
        viewOnLargerScreen: 'This content is best enjoyed on a larger screen.',
        pleaseSwitch: 'Please switch devices to view this content.',
        groupTable: {
            memberTitle: 'Member Groups',
            createdTitle: 'Created Groups',
            pendingInvites: 'Pending Invites',
            pending: (name: string) => `${name} - Pending`,
            groupName: 'Group Name',
            groupLeader: 'Leader',
            groupMembers: 'Members',
            pendingMembers: 'Invites',
        },
        emptyGroup: {
            pending: 'You have no pending group invites.',
            member: 'You are not a member of any groups.',
            created: 'You have not created any groups yet.',
        },
    }
}

export default groupStrings;
