import PageLayout from "../common/PageLayout";
import { useGroupContext } from "../../contexts/GroupContext";
import GroupTable from "../../components/groupTable/GroupTable";
import styles from "./GroupsPage.module.scss";
import DeleteGroup from "../../components/deleteGroup/DeleteGroup";
import CreateOrEditGroup from "../../components/createOrEditGroup/CreateOrEditGroup";
import { useState } from "react";
import { GroupType } from "../../types/GroupType";
import GroupViewMobile from "../../components/groupViewMobile/GroupViewMobile";
import { LAMPPOST_MOBILE_BREAKPOINTS } from "../../ui/constants";

const GroupsPage = () => {
    const {
        groups,
        deleteGroup,
        leaveGroup,
        acceptGroup,
        declineGroup
    } = useGroupContext();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [creatingOrEditingGroup, setCreatingOrEditingGroup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<GroupType>(null);
    const [groupType, setGroupType] = useState({
        isPendingMember: false,
        isGroupMember: false,
    });

    const onPrimaryButtonSelect = (isPendingMember: boolean, group: GroupType) => {
        if (isPendingMember) {
            acceptGroup(group._id, () => { });
        } else {
            onSelectGroup(group);
            onToggleCreateEditModal(true);
        }
    }

    const onSecondaryButtonSelect = (isPendingMember: boolean, isGroupMember: boolean, group: GroupType) => {
        if (isPendingMember) {
            declineGroup(group._id, () => { });
        } else {
            onSelectGroup(group);

            if (isGroupMember) {
                leaveGroup(group._id);
                onDeselectGroup();
            } else {
                onToggleDeleteModal(true);
            }
        }
    }

    const onDeleteGroup = () => {
        deleteGroup(selectedGroup._id);
        onDeselectGroup();
    }

    const onCloseModal = () => {
        if (
            window.innerWidth >= LAMPPOST_MOBILE_BREAKPOINTS.desktop ||
            !(showDeleteModal || creatingOrEditingGroup)
        ) {
            onDeselectGroup();
        }

        setShowDeleteModal(false);
        setCreatingOrEditingGroup(false)
    }

    const onSelectGroup = (groupInfo: GroupType) => {
        setSelectedGroup(groupInfo);
    }

    const onDeselectGroup = () => setSelectedGroup(null);
    const onToggleCreateEditModal = (isOpen: boolean) => setCreatingOrEditingGroup(isOpen);
    const onToggleDeleteModal = (isOpen: boolean) => setShowDeleteModal(isOpen);

    const onMobileSelect = (isPendingMember: boolean, isGroupMember: boolean, group: GroupType) => {
        setGroupType({ isPendingMember, isGroupMember });
        onSelectGroup(group);
    }

    return (
        <PageLayout>
            <div className={styles.groupsPageContainer}>
                <div
                    className={`
                        ${styles.tablesContainer}
                        ${showDeleteModal || creatingOrEditingGroup ? styles.showingModal : ''}
                        ${selectedGroup ? styles.hasSelectedGroup : ''}
                    `}
                >
                    <GroupTable
                        groupData={groups.created}
                        onToggleCreateEditModal={onToggleCreateEditModal}
                        onPrimaryButtonSelect={onPrimaryButtonSelect}
                        onSecondaryButtonSelect={onSecondaryButtonSelect}
                        onMobileSelect={onMobileSelect}
                    />
                    <GroupTable
                        groupData={groups.member}
                        onToggleCreateEditModal={onToggleCreateEditModal}
                        onPrimaryButtonSelect={onPrimaryButtonSelect}
                        onSecondaryButtonSelect={onSecondaryButtonSelect}
                        onMobileSelect={onMobileSelect}
                        isGroupMember
                    />
                    <GroupTable
                        groupData={groups.pending}
                        onToggleCreateEditModal={onToggleCreateEditModal}
                        onPrimaryButtonSelect={onPrimaryButtonSelect}
                        onSecondaryButtonSelect={onSecondaryButtonSelect}
                        onMobileSelect={onMobileSelect}
                        isPendingMember
                    />
                </div>
                <DeleteGroup
                    showDeleteGroup={showDeleteModal}
                    groupName={selectedGroup?.name || ''}
                    onDelete={onDeleteGroup}
                    onClose={onCloseModal}
                />
                <CreateOrEditGroup
                    onBack={onCloseModal}
                    groupInfo={selectedGroup || null}
                    showGroup={creatingOrEditingGroup}
                />
                {
                    selectedGroup && !(showDeleteModal || creatingOrEditingGroup) ? (
                        <GroupViewMobile
                            selectedGroup={selectedGroup}
                            groupType={groupType}
                            onPrimaryButtonSelect={onPrimaryButtonSelect}
                            onSecondaryButtonSelect={onSecondaryButtonSelect}
                            onClose={onCloseModal}
                        />
                    ) : null
                }
            </div>
        </PageLayout>
    )
}

export default GroupsPage;