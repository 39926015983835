import { useEffect, useRef, useState } from "react";
import strings from "../../i18n/i18nStrings";
import { ReactComponent as ChevronIcon } from '../../assets/icons/ChevronIcon.svg';
import styles from "./Dropdown.module.scss";
import { LAMP_POST_COLORS } from "../constants";
import Label from "../label/Label";

const Dropdown = ({
    options,
    setOptions,
    onSelect,
    multiSelect = false,
    label = '',
}: {
    options: any[];
    setOptions?: any[];
    onSelect: (selection?: any[]) => void;
    multiSelect?: boolean;
    label?: string;
}) => {
    const dropdownContainerRef = useRef<any>();
    const dropdownRef = useRef<any>();

    const [selectedOptions, setSelectedOptions] = useState(setOptions || []);
    const [isOpen, setIsOpen] = useState(false);

    const onSelectOption = (selectedOption: any) => {
        if (multiSelect) {
            const currentSelections = selectedOptions ? [...selectedOptions] : [];
            const currentSelectionIndex = currentSelections.findIndex(selection =>
                selection.id === selectedOption.id
            )

            if (currentSelectionIndex >= 0) {
                currentSelections.splice(currentSelectionIndex, 1);
            } else {
                currentSelections.push(selectedOption);
            }

            setSelectedOptions(currentSelections);
            onSelect(currentSelections);
        } else {
            setSelectedOptions([selectedOption]);
            onSelect([selectedOption]);
            setIsOpen(false);
        }
    }

    const getDropdownText = () => {
        if (multiSelect) {
            if (selectedOptions.length) {
                return strings.common.dropdownPlaceholder.multiSelected(selectedOptions.length.toString());
            }

            return strings.common.dropdownPlaceholder.multi;
        } else if (selectedOptions.length) {
            return selectedOptions[0]?.name;
        }

        return strings.common.dropdownPlaceholder.single;
    }

    useEffect(() => {
        const handleOutsideClick = (evt: { target: any; }) => {
            if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(evt.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, [dropdownContainerRef, setIsOpen]);

    return (
        <div
            className={styles.dropdownContainer}
            ref={dropdownContainerRef}
        >
            {label && <Label label={label} />}
            <div
                onClick={() => setIsOpen(prevState => !prevState)}
                className={styles.dropdownSelector}
                ref={dropdownRef}
            >
                <span color={LAMP_POST_COLORS.deepBlue}>
                    {getDropdownText()}
                </span>
                <span className={isOpen ? styles.openChevronContainer : ''}>
                    <ChevronIcon width={22} fill={LAMP_POST_COLORS.oliveGreen} />
                </span>
            </div>
            {
                isOpen && (
                    <div
                        className={styles.optionsSelector}
                        style={{ width: `${dropdownRef?.current?.offsetWidth || 0}px` }}
                    >
                        {
                            options.map(option => (
                                <div
                                    key={option.id}
                                    onClick={() => onSelectOption(option)}
                                    className={styles.optionContainer}
                                >
                                    <div className={styles.option}>
                                        <div className={styles.optionSelectedContainer}>
                                            {
                                                Array.isArray(selectedOptions) &&
                                                selectedOptions.find(selectedOption => selectedOption.id === option.id) && (
                                                    <div className={styles.optionSelected} />
                                                )
                                            }
                                        </div>
                                        <span color={LAMP_POST_COLORS.deepBlue}>
                                            {option.name}
                                        </span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Dropdown;