const strings = {
    common: {
        pages: {
            about: 'About Us',
            blog: 'Blog',
            faq: 'FAQs',
            legal: 'Legal',
        },
        copyright: '© 2024 LampPost. All rights reserved.',
        dropdownPlaceholder: {
            single: 'Select Option',
            multi: 'Select Options',
            multiSelected: (amount: string) => `Selected [${amount}]`
        },
        lampPost: 'LampPost',
        back: 'Back',
        cancel: 'Cancel',
        clear: 'Clear',
        close: 'Close',
        next: 'Next',
        previous: 'Previous',
        clearFilters: 'Clear Filters',
        submit: 'Submit',
        submitFilters: 'Apply',
        send: 'Send',
        remindMe: 'Remind Me Later',
        completeProfile: 'Complete Profile',
        updateProfile: 'Update Profile',
        update: 'Update',
        filters: 'Filters',
        showLess: 'Show less',
        photos: 'Photos',
        preview: 'Preview',
        edit: 'Edit',
        delete: 'Delete',
        decline: 'Decline',
        leave: 'Leave',
        logOut: 'Log out',
        accept: 'Accept',
        create: 'Create',
        actions: 'Actions',
        repeats: {
            none: 'Non-repeating',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            yearly: 'Yearly',
        },
        datePicker: (date: string, time: string) => `${date} @ ${time}`,
        quickDate: (day: string, month: string, time: string) => `${day}/${month} @ ${time}`,
        sameDate: (day: string, month: string, startTime: string, endTime: string) => `${month}/${day} @ ${startTime} - ${endTime}`,
        differentDate: (startDay: string, startMonth: string, startTime: string, endDay: string, endMonth: string, endTime: string) => `${startMonth}/${startDay} @ ${startTime} - ${endMonth}/${endDay} @ ${endTime}`,
        loading: 'Loading...',
        yes: 'Yes',
        no: 'No'
    }
}

export default strings;