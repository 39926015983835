import { useState } from "react";
import { useEventsContext } from "../../contexts/EventsContext";
import AdminEventPreview from "../adminEventPreview/AdminEventPreview";
import styles from "./EventModeration.module.scss";
import strings from "../../i18n/i18nStrings";

const EventModeration = () => {
    const { moderationEvents } = useEventsContext();
    const [previewEventIndex, setPreviewEventIndex] = useState(null);

    const onNavToEvent = (isNext?: boolean) => {
        if (isNext) {
            if (previewEventIndex < moderationEvents.length - 1) {
                setPreviewEventIndex(prevIndex => prevIndex + 1);
            }
        } else if (previewEventIndex > 0) {
            setPreviewEventIndex(prevIndex => prevIndex - 1);
        }
    }

    const tester = [];

    for (let i = 0; i < 50; i++) {
        tester.push(...moderationEvents);
    }

    return (
        <>
            <div>
                <div className={styles.pageTitle}>{strings.admin.eventsModeration.title}</div>
                <div className={styles.moderationTable}>
                    <div className={styles.tableHeader}>{strings.admin.eventsModeration.name}</div>
                    <div className={styles.tableHeader}>{strings.admin.eventsModeration.creator}</div>
                </div>
                <div className={`${styles.moderationTable} ${styles.content}`}>
                    {
                        moderationEvents.map((moderationEvent, idx) => (
                            <>
                                <div>
                                    <span
                                        className={styles.eventPreviewLink}
                                        onClick={() => setPreviewEventIndex(idx)}
                                    >
                                        {moderationEvent.name}
                                    </span>
                                </div>
                                <div>
                                    {moderationEvent.creator}
                                </div>
                            </>
                        ))
                    }
                </div>
            </div>
            <AdminEventPreview
                eventData={moderationEvents[previewEventIndex]}
                onClose={() => setPreviewEventIndex(null)}
                onNavToEvent={onNavToEvent}
                hasNext={previewEventIndex < moderationEvents.length - 1}
                hasPrev={previewEventIndex > 0}
            />
        </>
    )
}

export default EventModeration;