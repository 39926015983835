const homeStrings = {
    homeScreen: {
        createEvent: 'Create Event',
        events: 'Events',
        eventList: {
            title: 'My Events',
            description: 'Monitor and track events in real-time.'
        },
        groups: {
            title: 'My Groups',
            description: 'Connect to communities, shared interests, and local meetups.'
        },
        analytics: {
            title: 'Analytics Dashboard ',
            description: 'Dive into data with powerful analytics and actionable insights.'
        },
        settings: {
            title: 'My Settings',
            description: 'Tailor and secure your account with flexible options.'
        },
        admin: {
            title: 'Admin Dashboard',
            description: 'Dashboard for admin users.'
        },
    }
}

export default homeStrings;