
import strings from "../../i18n/i18nStrings";
import Modal from "../../ui/modal/Modal";
import BackHeader from "../mobile/backHeader/BackHeader";
import styles from "./DeleteEvent.module.scss";
import DeleteEventInputs from "./inputs/DeleteEventInputs";

const DeleteEvent = ({
    showDeleteEvent,
    onDelete,
    onClose,
}: {
    showDeleteEvent: boolean;
    onDelete: () => void;
    onClose: () => void;
}) => {

    return (
        <>
            {
                showDeleteEvent && (
                    <div className={styles.deleteEventMobile}>
                        <>
                            <BackHeader
                                onBack={onClose}
                                backText={strings.eventDetailsScreen.backtoEvent}
                            />
                            <div className={styles.deleteForm}>
                                <DeleteEventInputs
                                    onClose={onClose}
                                    onDelete={onDelete}
                                />
                            </div>
                        </>
                    </div>
                )
            }
            <Modal
                isOpen={showDeleteEvent}
                onClose={onClose}
                isThin
            >
                <DeleteEventInputs
                    onClose={onClose}
                    onDelete={onDelete}
                />
            </Modal>
        </>
    )
}

export default DeleteEvent;