import Label from "../label/Label";
import styles from './DatePicker.module.scss';
import { useEffect, useState } from "react";

const DatePicker = ({
    initialValue,
    onChange,
    label,
    type = "datetime-local"
}: {
    initialValue?: Date;
    onChange: (date: Date) => void;
    label: string;
    type?: "datetime-local" | "date";
}) => {
    const [modifiedInitialValue, setModifiedInitialValue] = useState<any>();

    useEffect(() => {
        const date = initialValue || new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-indexed
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        setModifiedInitialValue(formattedDate);
    }, [initialValue]);

    return (
        <div>
            <Label label={label} />
            <div>
                <input
                    aria-label="Date and time"
                    type={type}
                    onChange={e => onChange(new Date(e.target.value))}
                    className={styles.datePicker}
                    value={modifiedInitialValue}
                />
            </div>
        </div>
    );
}

export default DatePicker;