import { useEffect, useState } from "react";
import { SignedIn } from '@clerk/clerk-react';
import styles from './CreateOrEditEventInputs.module.scss';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/ChevronIcon.svg';
import { useAppContext } from "../../../contexts/AppContext";
import { useEventsContext } from "../../../contexts/EventsContext";
import { urlValidator } from "../../../utils/urlChecker";
import Loader from "../../loader/Loader";
import strings from "../../../i18n/i18nStrings";
import Input from "../../../ui/input/Input";
import LocationAutocomplete from "../../../ui/locationAutocomplete/LocationAutocomplete";
import { LAMP_POST_COLORS } from "../../../ui/constants";
import PhotoUpload from "../../photoUpload/PhotoUpload";
import WarningLabel from "../../common/warningLabel/WarningLabel";
import Dropdown from "../../../ui/dropdown/Dropdown";
import { FILTER_AGES, FILTER_TAGS } from "../../filters/filters.constants";
import { REPEAT_VALUES } from "../../common/constants/createEvent";
import RadioButtonGroup from "../../../ui/radioButtonGroup/RadioButtonGroup";
import Button, { BUTTON_VARIANTS } from "../../../ui/button/Button";
import LampPostDatePicker from "../../../ui/datePicker/DatePicker";
import { useGroupContext } from "../../../contexts/GroupContext";

const DEFAULT_EDITED_EVENT = {
    id: null,
    name: '',
    location: null,
    description: '',
    images: [],
    cost: 0,
    tags: [],
    ages: [],
    startDateTime: new Date(new Date().setDate(new Date().getDate() + 1)),
    endDateTime: new Date(new Date().setDate(new Date().getDate() + 1)),
    repeatType: 0,
    ticketLink: '',
    isHost: true,
    passcode: '',
    whiteList: '',
    groups: [],
}

const CreateOrEditEventInputs = ({
    onCancel,
    onSuccess,
    eventInfo
}: {
    onCancel: () => void;
    onSuccess?: () => void;
    eventInfo?: any;
}) => {
    const { userInfo } = useAppContext();
    const { createOrEditEvent } = useEventsContext();
    const { groups } = useGroupContext();

    const [editedEvent, setEditedEvent] = useState(DEFAULT_EDITED_EVENT);

    const [groupOptions, setGroupOptions] = useState([]);
    const [isAttending, setIsAttending] = useState(typeof userInfo?.isAdmin === "boolean" ? !userInfo.isAdmin : true);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
    const [isSubmittingEvent, setIsSubmittingEvent] = useState(false);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

    useEffect(() => {
        const {
            name,
            location,
            startDateTime,
            endDateTime,
            cost,
            description,
            ticketLink
        } = editedEvent;

        setIsSubmitEnabled(
            !!name && !!location && !!startDateTime && !!endDateTime &&
            cost !== null && !!description &&
            (!ticketLink || urlValidator(ticketLink))
        );
    }, [
        editedEvent
    ]);

    useEffect(() => {
        const currentDate = new Date();

        setEditedEvent({
            id: eventInfo?._id || null,
            name: eventInfo?.name || '',
            location: eventInfo?.location || null,
            description: eventInfo?.description || '',
            images: eventInfo?.images || [],
            cost: eventInfo?.cost || 0,
            tags: eventInfo?.tags || [],
            ages: eventInfo?.ages || [],
            startDateTime: new Date(eventInfo?.startDateTime ? eventInfo?.startDateTime : currentDate.setDate(currentDate.getDate() + 1)),
            endDateTime: new Date(eventInfo?.endDateTime ? eventInfo?.endDateTime : currentDate.setDate(currentDate.getDate() + 1)),
            repeatType: eventInfo?.repeatType || 0,
            ticketLink: eventInfo?.ticketLink || '',
            isHost: eventInfo?.isHost || true,
            passcode: eventInfo?.passcode || '',
            whiteList: eventInfo?.whiteList?.join(', ') || '',
            groups: eventInfo?.groups.map((group) => ({
                id: group,
                value: group
            })) || [],
        });
    }, [eventInfo]);

    useEffect(() => {
        setGroupOptions([...groups?.created].sort((groupA, groupB) => {
            if (groupA.name > groupB.name) {
                return -1;
            }

            if (groupA.name > groupB.name) {
                return 1;
            }

            return 0;
        })
            .map((group) => ({
                ...group,
                id: group._id,
                value: group._id
            }))
        )
    }, [groups?.created]);

    const submitEvent = () => {
        setIsSubmittingEvent(true);

        createOrEditEvent(
            {
                ...editedEvent,
                startDateTime: editedEvent?.startDateTime.getTime(),
                endDateTime: editedEvent?.endDateTime.getTime(),
                whiteList: editedEvent.whiteList?.length ? editedEvent.whiteList.replace(/ /g, '').split(',') : null,
            },
            isAttending,
            () => {
                setIsSubmittingEvent(false);
                onSuccess();
                setIsAttending(false);
                onCancel();
            }
        );
    }

    const updateEvent = (key: string, value: any) => {
        setEditedEvent({
            ...editedEvent,
            [key]: value
        })
    }

    return isSubmittingEvent ? (
        <Loader />
    ) : (
        <>
            <h2 className={styles.headerText}>
                {strings.createOrEditEventScreen[editedEvent.id ? 'editEvent' : 'createEvent']}
            </h2>
            <div className={styles.editContentsContainer}>
                <Input
                    label={strings.createOrEditEventScreen.eventName}
                    text={editedEvent.name}
                    onChange={(updatedName) => updateEvent('name', updatedName)}
                />
                <LocationAutocomplete
                    initialLocation={editedEvent.location}
                    onChange={(updatedLocation) => updateEvent('location', updatedLocation)}
                />
                <LampPostDatePicker
                    initialValue={editedEvent.startDateTime}
                    onChange={(updatedDate) => updateEvent('startDateTime', updatedDate)}
                    label={strings.createOrEditEventScreen.eventStartDateTime}
                />
                <LampPostDatePicker
                    initialValue={editedEvent.endDateTime}
                    onChange={(updatedDate) => updateEvent('endDateTime', updatedDate)}
                    label={strings.createOrEditEventScreen.eventEndDateTime}
                />
                <Input
                    label={strings.createOrEditEventScreen.eventDescription}
                    text={editedEvent.description}
                    onChange={(updatedDesc) => updateEvent('description', updatedDesc)}
                    numberOfLines={5}
                />

                <div
                    onClick={() => setShowAdvancedOptions(prevState => !prevState)}
                >
                    <div className={`${styles.advancedOptionsContainer} ${showAdvancedOptions ? styles.opened : ''}`}>
                        <h3>{strings.createOrEditEventScreen.advancedOptions}</h3>
                        <ChevronIcon
                            width={22}
                            fill={LAMP_POST_COLORS.oliveGreen}
                            className={showAdvancedOptions ? styles.openedChevron : ''}
                        />
                    </div>
                </div>

                {
                    showAdvancedOptions && (
                        <>
                            <PhotoUpload
                                uploadedPhotos={editedEvent.images}
                                onUpload={(updatedImages) => updateEvent('images', updatedImages)}
                            />
                            <Input
                                label={strings.createOrEditEventScreen.eventCost}
                                text={editedEvent.cost.toString()}
                                onChange={(enteredCost) => updateEvent('cost', parseFloat(enteredCost) || 0)}
                            />
                            <Input
                                label={strings.createOrEditEventScreen.linkToTickets}
                                text={editedEvent.ticketLink}
                                onChange={(updatedTicket) => updateEvent('ticketLink', updatedTicket)}
                            />
                            {
                                editedEvent.ticketLink && !urlValidator(editedEvent.ticketLink) && (
                                    <WarningLabel
                                        text={strings.createOrEditEventScreen.ticketLinkError}
                                    />
                                )
                            }
                            <Dropdown
                                setOptions={editedEvent.tags?.map((eventTag: any) => Object.values(FILTER_TAGS).find(tag => tag.id === eventTag))}
                                options={Object.values(FILTER_TAGS)}
                                onSelect={(updatedTags) => updateEvent('tags', updatedTags?.map(tag => tag.id))}
                                label={strings.createOrEditEventScreen.eventTags}
                                multiSelect
                            />
                            <Dropdown
                                setOptions={[Object.values(REPEAT_VALUES).find(repeat => repeat.id === editedEvent.repeatType)]}
                                options={Object.values(REPEAT_VALUES)}
                                onSelect={(repeatOption: any) => updateEvent('repeatType', repeatOption[0]?.id)}
                                label={strings.createOrEditEventScreen.eventRepeating}
                            />
                            <Dropdown
                                setOptions={editedEvent.ages}
                                options={Object.values(FILTER_AGES)}
                                onSelect={(updatedAges) => updateEvent('ages', updatedAges?.map(age => age.id))}
                                label={strings.createOrEditEventScreen.eventAges}
                                multiSelect
                            />
                            {
                                editedEvent.isHost && (
                                    <>
                                        {/*
                                            TODO: Determine if a passcode is needed when a white list exists
                                            <Input
                                                label={strings.createOrEditEventScreen.passcode}
                                                text={eventPasscode}
                                                onChange={setEventPasscode}
                                            />
                                        */}
                                        {
                                            userInfo?.userGroups?.created?.length ? (
                                                <Dropdown
                                                    setOptions={editedEvent.groups}
                                                    options={groupOptions}
                                                    onSelect={(updatedGroups) => updateEvent('groups', updatedGroups?.map(group => group._id))}
                                                    label={strings.createOrEditEventScreen.groups}
                                                    multiSelect
                                                />
                                            ) : null
                                        }
                                        {/*
                                            TODO: Detemine if this is needed with groups existing
                                            <Input
                                                label={strings.createOrEditEventScreen.allowedEmails}
                                                text={editedEvent.whiteList}
                                                onChange={(updatedWhiteList) => updateEvent('whiteList', updatedWhiteList)}
                                                numberOfLines={2}
                                            />
                                        */}
                                    </>
                                )
                            }
                            {
                                <SignedIn>
                                    <RadioButtonGroup
                                        options={[{
                                            id: 0,
                                            name: strings.common.yes
                                        }, {
                                            id: 1,
                                            name: strings.common.no
                                        }]}
                                        initialOptionSelected={{
                                            id: 0,
                                            name: strings.common.yes
                                        }}
                                        onSelect={(selection) => setIsAttending(selection.id === 0)}
                                        label={strings.createOrEditEventScreen.attendingEvent}
                                    />
                                </SignedIn>
                            }
                        </>
                    )
                }
                <div className={styles.respondToButtonContainer}>
                    <div className={styles.buttonContainer}>
                        <Button
                            onPress={onCancel}
                            roleVariant={BUTTON_VARIANTS.SECONDARY}
                        >
                            <span>{strings.common.cancel}</span>
                        </Button>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            onPress={submitEvent}
                            disabled={!isSubmitEnabled}
                        >
                            <span>{strings.common.submit}</span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateOrEditEventInputs;