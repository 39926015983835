import strings from "../../../i18n/i18nStrings"

export const NAVIGATION_IDS = {
    LOGIN: "login",
    FRONTPAGE: "/events",
    DASHBOARD: "dashboard",
    EVENTS: "events",
    GROUPS: "groups",
    ANALYTICS: "analytics",
    SETTINGS: "settings",
    ABOUT: "about-us",
    BLOG: "blog",
    FAQ: "faq",
    LEGAL: "legal",
    ADMIN: "admin",
}

export const NAV_LIST_DATA = {
    GROUPS: {
        title: strings.homeScreen.groups.title,
        description: strings.homeScreen.groups.description,
        url: NAVIGATION_IDS.GROUPS,
        userPermissionCheck: () => true,
    },
    SETTINGS: {
        title: strings.homeScreen.settings.title,
        description: strings.homeScreen.settings.description,
        url: NAVIGATION_IDS.SETTINGS,
        userPermissionCheck: () => true,
    },
    ANALYTICS: {
        title: strings.homeScreen.analytics.title,
        description: strings.homeScreen.analytics.description,
        url: NAVIGATION_IDS.ANALYTICS,
        // TODO: Update this permission to check if they have analytics, not being an admin
        userPermissionCheck: ({ isAdmin = false }) => isAdmin, 
    },
    ADMIN: {
        title: strings.homeScreen.admin.title,
        description: strings.homeScreen.admin.description,
        url: NAVIGATION_IDS.ADMIN,
        userPermissionCheck: ({ isAdmin = false }) => isAdmin,
    }
}