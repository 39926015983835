import { /*useCallback, useEffect,*/ useRef, useState } from "react";
import { useEventsContext } from "../../contexts/EventsContext";
import strings from "../../i18n/i18nStrings";
import styles from "./EventDisplayList.module.scss";
import FiltersHeader from "../filters/filtersHeader/FiltersHeader";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import Loader from "../loader/Loader";
import EmptyDisplayList from "./emptyDisplayList/EmptyDisplayList";
import EventDisplayListItem from "./eventDisplayListItem/EventDisplayListItem";
import { useFilterContext } from "../../contexts/FilterContext";
import FiltersInputs from "../filters/filtersInputs/FiltersInputs";
import { useAppContext } from "../../contexts/AppContext";
import ProfileInfoModule from "../profileInfoModule/ProfileInfoModule";
import CreateOrEditEvent from "../createOrEditEvent/CreateOrEditEvent";

const EventDisplayList = () => {
    const { showCompleteProfileModule } = useAppContext();

    const {
        events,
        eventsLoading,
        // pageNum,
        // onPageIncrease,
        // hasMoreEvents,
    } = useEventsContext();

    const { showFilters } = useFilterContext();

    const [showCreateEvent, setShowCreateEvent] = useState(false);
    const onEventCreation = () => setShowCreateEvent(true);
    const onCancelEventCreation = () => setShowCreateEvent(false);
    // const [scrollPosition, setScrollPosition] = useState(0);

    const listContainerRef = useRef(null);
    // const observerRef = useRef(null);

    // TODO: Fix infinite scroll
    // const lastEventRef = useCallback(
    //     (node) => {
    //         if (eventsLoading) {
    //             return;
    //         }

    //         if (hasMoreEvents) {
    //             setScrollPosition(node?.getAttribute('id'));

    //             if (observerRef.current) {
    //                 observerRef.current.disconnect();
    //             }

    //             observerRef.current = new IntersectionObserver((entries) => {
    //                 if (entries[0].isIntersecting) {
    //                     onPageIncrease();
    //                 }
    //             });

    //             if (node) {
    //                 observerRef.current.observe(node);
    //             }
    //         }
    //     },

    //     [eventsLoading, hasMoreEvents, onPageIncrease]
    // )

    // useEffect(() => {
    // if (events?.length && scrollPosition && pageNum > 0) {
    //     const yOffset = -150;
    //     const element = document.getElementById(`${scrollPosition}`);

    //     if (element) {
    //         const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

    //         listContainerRef.current.scrollTo({
    //             top: y,
    //             behavior: 'smooth'
    //         });
    //     }
    // }
    // }, [events, scrollPosition, pageNum]);

    return (
        <>
            <div className={styles.sidebarContainer}>
                {showFilters && (
                    <div className={styles.filtersContainer}>
                        <FiltersInputs />
                    </div>
                )}
                <ProfileInfoModule />
                <div
                    className={`
                        ${styles.eventsListContainer}
                        ${(showCreateEvent || showFilters || showCompleteProfileModule) ? styles.hiddenMobile : ''}
                    `}
                >
                    <FiltersHeader />
                    <div
                        className={styles.eventsContainer}
                        ref={listContainerRef}
                    >
                        {
                            eventsLoading ? (
                                <Loader />
                            ) : events.length ? (
                                <div className={styles.eventsContainerDisplayItems}>
                                    {
                                        events.map((event, idx) => idx < events.length - 1 ? (
                                            <EventDisplayListItem
                                                key={idx}
                                                event={event}
                                            />
                                        ) : (
                                            <EventDisplayListItem
                                                key={idx}
                                                event={event}
                                            // loadRef={lastEventRef}
                                            />
                                        ))
                                    }
                                </div>
                            ) : (
                                <EmptyDisplayList
                                    onCtaClick={onEventCreation}
                                />
                            )
                        }
                        {/* <div
                            style={{
                                backgroundColor: 'red',
                                height: "50px",
                                width: "100%"
                            }}
                            ref={containerRef}
                        /> */}
                    </div>
                    <div className={styles.createButtonContainer}>
                        <Button
                            onPress={onEventCreation}
                            roleVariant={BUTTON_VARIANTS.CTA}
                        >
                            <span>{strings.createOrEditEventScreen.createEvent}</span>
                        </Button>
                    </div>
                </div>
                <CreateOrEditEvent
                    showCreateEvent={showCreateEvent}
                    onClose={onCancelEventCreation}
                />
            </div>
        </>
    )
}

export default EventDisplayList;