
import strings from "../../i18n/i18nStrings";
import { LAMPPOST_MOBILE_BREAKPOINTS } from "../../ui/constants";
import Modal from "../../ui/modal/Modal";
import BackHeader from "../mobile/backHeader/BackHeader";
import styles from "./DeleteGroup.module.scss";
import DeleteGroupInputs from "./inputs/DeleteGroupInputs";

const DeleteGroup = ({
    showDeleteGroup,
    onDelete,
    onClose,
    groupName,
}: {
    showDeleteGroup: boolean;
    onDelete: () => void;
    onClose: () => void;
    groupName: string;
}) => (
    <>
        {
            showDeleteGroup && (
                <div className={styles.deleteGroupMobile}>
                    <>
                        <BackHeader
                            onBack={onClose}
                            backText={
                                window.innerWidth >= LAMPPOST_MOBILE_BREAKPOINTS.desktop ?
                                    strings.groupScreen.backToGroups :
                                    strings.groupScreen.backToGroup(groupName)
                            }
                        />
                        <div className={styles.deleteForm}>
                            <DeleteGroupInputs
                                onClose={onClose}
                                onDelete={onDelete}
                            />
                        </div>
                    </>
                </div>
            )
        }
        <Modal
            isOpen={showDeleteGroup}
            onClose={onClose}
            isThin
        >
            <DeleteGroupInputs
                onClose={onClose}
                onDelete={onDelete}
            />
        </Modal>
    </>
)

export default DeleteGroup;