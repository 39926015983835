const userProfileStrings = {
    userProfileScreen: {
        title: 'Personalize Your Experience',
        ask: "At LampPost, our mission is to bring people together and make it easier to find activities you love. To help us better understand our community and build a more inclusive experience, we'd love to learn a little more about you.",
        optional: "This part is completely optional, so feel free to share as much or as little as you’d like!",
        interests: 'Interests',
        moreInterests: (additionalInterestsCount: number) => `+${additionalInterestsCount} more`,
        eventsHistory: 'Events History',
        createProfileTitle: 'Create Profile',
        editProfileTitle: 'Edit Profile',
        userName: 'Name',
        birthday: 'Birthday (MM/DD/YYYY)',
        gender: 'Gender',
        sexualOrientation: 'Sexual Orientation',
        educationLevel: 'Education',
        race: 'Race',
        location: 'Location',
        description: 'Bio (min 5, max 250)',
    }
}

export default userProfileStrings;