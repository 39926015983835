import { GroupType } from "../../types/GroupType";
import styles from "./GroupTable.module.scss";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import strings from "../../i18n/i18nStrings";
import { useState } from "react";
import { LAMP_POST_COLORS, LAMPPOST_MOBILE_BREAKPOINTS } from "../../ui/constants";
import { ReactComponent as ChevronIcon } from '../../assets/icons/ChevronIcon.svg';

const GroupTable = ({
    groupData,
    isGroupMember,
    isPendingMember,
    onToggleCreateEditModal,
    onPrimaryButtonSelect,
    onSecondaryButtonSelect,
    onMobileSelect
}: {
    groupData: GroupType[];
    isGroupMember?: boolean;
    isPendingMember?: boolean;
    onToggleCreateEditModal: (isOpen: boolean) => void;
    onPrimaryButtonSelect: (isPendingMember: boolean, group: GroupType) => void;
    onSecondaryButtonSelect: (isPendingMember: boolean, isGroupMember: boolean, group: GroupType) => void;
    onMobileSelect: (isPendingMember: boolean, isGroupMember: boolean, group: GroupType) => void;
}) => {
    const [isMobileOpen, setIsMobileOpen] = useState(false);

    const onToggleMobileTable = () => {
        if (window.innerWidth < LAMPPOST_MOBILE_BREAKPOINTS.desktop && groupData?.length) {
            setIsMobileOpen(prevState => !prevState);
        }
    }

    return (
        <>
            <div className={styles.groupTableContainer}>
                <div>
                    <div className={styles.groupTitleContainer}>
                        <div
                            className={styles.titleContainer}
                            onClick={onToggleMobileTable}
                        >
                            <span className={styles.title}>
                                {isPendingMember ? strings.groupScreen.groupTable.pendingInvites : ''}
                                {isGroupMember ? strings.groupScreen.groupTable.memberTitle : ''}
                                {!isGroupMember && !isPendingMember ? strings.groupScreen.groupTable.createdTitle : ''}
                            </span>
                            {
                                groupData?.length ? (
                                    <ChevronIcon
                                        width={22}
                                        fill={LAMP_POST_COLORS.amber}
                                        className={`${styles.chevron} ${isMobileOpen ? styles.openedChevron : ''}`}
                                    />
                                ) : null
                            }
                        </div>
                        {!isGroupMember && !isPendingMember ? (
                            <div className={styles.createDesktop}>
                                <Button onPress={() => onToggleCreateEditModal(true)}>
                                    <span>{strings.groupScreen.createGroup}</span>
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </div>
                {
                    groupData?.length ? (
                        <div className={`${styles.groupTableContainer} ${isMobileOpen ? '' : styles.mobileClosed}`}>
                            <div className={styles.groupTable}>
                                <div
                                    className={`
                                                    ${styles.header}
                                                    ${styles.groupNameCell}
                                                    ${isPendingMember || isGroupMember ? styles.expandedGroupName : ''}
                                                `}
                                >
                                    {strings.groupScreen.groupTable.groupName}
                                </div>
                                {
                                    isPendingMember || isGroupMember ? (
                                        <div className={`${styles.header} ${styles.groupLeaderCell}`}>
                                            {strings.groupScreen.groupTable.groupLeader}
                                        </div>
                                    ) : (
                                        <>
                                            <div className={`${styles.header} ${styles.groupMemberCell}`}>
                                                {strings.groupScreen.groupTable.groupMembers}
                                            </div>
                                            <div className={`${styles.header} ${styles.groupInviteCell}`}>
                                                {strings.groupScreen.groupTable.pendingMembers}
                                            </div>
                                        </>
                                    )
                                }
                                <div className={`${styles.header} ${styles.actions} ${styles.actionsCell}`}>
                                    {strings.common.actions}
                                </div>
                            </div>
                            <div className={`${styles.groupTable} ${styles.cellGroup}`}>
                                {
                                    groupData?.map((group, idx) => {
                                        const isLastRow = idx === groupData.length - 1;

                                        return (
                                            <>
                                                <div
                                                    className={`
                                                                    ${styles.cell}
                                                                    ${styles.groupNameCell}
                                                                    ${isPendingMember || isGroupMember ? styles.expandedGroupName : ''}
                                                                    ${isLastRow ? styles.lastRow : ''}
                                                                `}
                                                    onClick={() => onMobileSelect(isPendingMember, isGroupMember, group)}
                                                >
                                                    {group.name}
                                                </div>
                                                {
                                                    isPendingMember || isGroupMember ? (
                                                        <div
                                                            className={`
                                                                        ${styles.cell}
                                                                        ${styles.groupLeaderCell}
                                                                        ${isLastRow ? styles.lastRow : ''}
                                                                    `}
                                                        >
                                                            {
                                                                typeof group.groupLeader === 'string' ?
                                                                    group.groupLeader :
                                                                    group.groupLeader.name || group.groupLeader.email
                                                            }
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className={`
                                                                            ${styles.cell}
                                                                            ${styles.groupMemberCell}
                                                                            ${isLastRow ? styles.lastRow : ''}
                                                                        `}
                                                            >
                                                                {group.members.map((member: any) => {
                                                                    if (member?._id) {
                                                                        return (
                                                                            <div>
                                                                                {member.name || member.email}
                                                                            </div>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <div>
                                                                            <span>{strings.groupScreen.groupTable.pending(member)}</span>
                                                                        </div>);
                                                                })}
                                                            </div>
                                                            <div
                                                                className={`
                                                                            ${styles.cell}
                                                                            ${styles.groupInviteCell}
                                                                            ${isLastRow ? styles.lastRow : ''}
                                                                        `}
                                                            >
                                                                {
                                                                    group.pending.map((pendingMember: any) => {
                                                                        if (pendingMember?._id) {
                                                                            return (
                                                                                <div>{pendingMember.name || pendingMember.email}</div>
                                                                            )
                                                                        }

                                                                        return (
                                                                            <div>
                                                                                <span>{pendingMember}</span>
                                                                            </div>);
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                <div
                                                    className={`
                                                                ${styles.cell}
                                                                ${styles.actionContainer}
                                                                ${styles.actionsCell}
                                                                ${isLastRow ? styles.lastRow : ''}
                                                        `}>
                                                    {
                                                        isGroupMember ? null : (
                                                            <Button onPress={() => onPrimaryButtonSelect(isPendingMember, group)}>
                                                                <span>
                                                                    {
                                                                        isPendingMember ?
                                                                            strings.common.accept :
                                                                            strings.common.edit
                                                                    }
                                                                </span>
                                                            </Button>
                                                        )
                                                    }
                                                    <Button
                                                        roleVariant={BUTTON_VARIANTS.DESTRUCTIVE}
                                                        onPress={() => onSecondaryButtonSelect(isPendingMember, isGroupMember, group)}
                                                    >
                                                        <span>
                                                            {isPendingMember ? strings.common.decline : ''}
                                                            {isGroupMember ? strings.common.leave : ''}
                                                            {!isPendingMember && !isGroupMember ? strings.common.delete : ''}
                                                        </span>
                                                    </Button>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) : (
                        <div className={styles.emptyTable}>
                            {isPendingMember ? strings.groupScreen.emptyGroup.pending : null}
                            {isGroupMember ? strings.groupScreen.emptyGroup.member : null}
                            {!isPendingMember && !isGroupMember ? strings.groupScreen.emptyGroup.created : null}
                        </div>
                    )
                }
                {!isGroupMember && !isPendingMember ? (
                    <div className={styles.createMobile}>
                        <Button onPress={() => onToggleCreateEditModal(true)}>
                            <span>{strings.groupScreen.createGroup}</span>
                        </Button>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default GroupTable;