import { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { DEFAULT_HEADERS, lampPostFetch } from '../utils/fetchHelpers';
import {
    CREATE_EVENT,
    DELETE_EVENT,
    UPDATE_EVENT,
    GET_EVENTS,
    JOIN_EVENT,
    GET_EVENT,
    GET_MODERATION_EVENTS,
    ACCEPT_MODERATED_EVENT,
    SHARE_EVENT,
} from '../components/common/constants/endpoints';
import { useAppContext } from './AppContext';
import { EventType } from '../types/EventType';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFilterContext } from './FilterContext';
import { FILTER_EVENT_TYPES } from '../components/filters/filters.constants';
import { LAMPPOST_MOBILE_BREAKPOINTS } from '../ui/constants';
import { useUser } from '@clerk/clerk-react';

type EventsContextValues = {
    events: EventType[];
    moderationEvents: EventType[];
    selectedEvent: string;
    eventsLoading: boolean;
    createOrEditEvent: (newEvent: object, isAttending: boolean, onSuccess?: () => void) => void;
    joinOrLeaveEvent: (eventId: string) => void;
    acceptModeratedEvent: (eventId: string) => void;
    shareEvent: (recipients: string[]) => void;
    deleteEvent: (eventId: string, isModeratedEvent?: boolean) => void;
    onSelectEvent: (eventId: string) => void;
    onDeselectEvent: () => void;
    onPageIncrease: () => void;
    fetchEvents: () => void;
    viewingEvent: boolean;
    hasMoreEvents: boolean;
    pageNum: number;
}

export const EventsContext = createContext({} as EventsContextValues);

export const useEventsContext = () => useContext(EventsContext);

export const EventsContextProvider = ({ children }: { children: ReactElement }) => {
    const {
        server,
        userInfo,
        userLocation,
        getUpdatedUser,
    } = useAppContext();
    const {
        filters,
        refetchWithFilters,
        onRefetchedWithFilters
    } = useFilterContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { isSignedIn } = useUser();

    const [events, setEvents] = useState([]);
    const [moderationEvents, setModerationEvents] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<string>(null);
    const [pageNum, setPageNum] = useState(0);
    const [hasMoreEvents, setHasMoreEvents] = useState(true);

    const fetchEvents = async () => {
        if ((!isSignedIn || !!userInfo) && hasMoreEvents) {
            setEventsLoading(true);

            // Fetch all events
            if (filters.eventType[0].id === FILTER_EVENT_TYPES.allEvents.id) {
                let locationValue;

                if (filters?.location) {
                    locationValue = filters.location
                } else {
                    locationValue = {
                        lat: userLocation?.latitude,
                        lng: userLocation?.longitude,
                    }
                }

                const filterQueryContents = [`pageNum=${pageNum}&locLat=${locationValue.lat}&locLng=${locationValue.lng}`];

                Object.keys(filters).forEach((filterKey) => {
                    const filterValue = filters[filterKey];

                    if (filterKey === 'cost' || filterKey === 'distance' || filterKey === 'tags' || filterKey === 'eventType') {
                        filterValue.forEach((filterArrayValue) => filterQueryContents.push(`${filterKey}=${filterArrayValue.id}`));
                    } else if (filterKey !== 'location') {
                        filterQueryContents.push(`${filterKey}=${filterValue || null}`);
                    }
                });

                await new Promise(() => {
                    lampPostFetch(
                        `${GET_EVENTS}?${userInfo ? `userId=${userInfo?._id || null}&` : ''}${filterQueryContents.join('&')}`,
                        {},
                        async (result) => {
                            setEventsLoading(false);

                            const newEvents = result?.events;
                            setHasMoreEvents(result?.hasMoreEvents);

                            if (newEvents && newEvents?.length) {
                                setEvents(newEvents);

                                if (pageNum === 0) {
                                    const specifiedEventId = searchParams.get('eventId');

                                    if (specifiedEventId) {
                                        onSelectEvent(specifiedEventId);
                                    } else if (window.innerWidth >= LAMPPOST_MOBILE_BREAKPOINTS.desktop) {
                                        onSelectEvent(newEvents[0]._id);
                                    }
                                }
                            } else {
                                setEvents([]);
                            }
                        }
                    );
                })
            } else {
                // fetchedEvents = userInfo.events[userEventType]
                let fetchedEvents = userInfo.events.previous;

                switch (filters.eventType[0].id) {
                    case 1:
                        fetchedEvents = userInfo.events.hosting;
                        break;
                    case 2:
                        fetchedEvents = userInfo.events.created;
                        break;
                    case 3:
                        fetchedEvents = userInfo.events.attending;
                        break;
                    default:
                        break
                }

                await Promise.all(fetchedEvents.map(id =>
                    fetch(`${server}${GET_EVENT(id)}`, {
                        method: "GET",
                        headers: DEFAULT_HEADERS
                    })
                        .then(resp => resp.json())
                )).then(results => {
                    setEventsLoading(false);
                    setEvents(results);
                    onSelectEvent(results[0]?._id);
                });
            }
        }
    }

    const fetchModeratedEvents = () => {
        lampPostFetch(
            GET_MODERATION_EVENTS,
            {},
            async (result) => {
                setModerationEvents(result)
            }
        );
    }

    const createOrEditEvent = (eventInfo: any, isAttending: boolean = true, onSuccess?: () => void) => {
        const locationData = eventInfo.location.geometry ? {
            id: eventInfo.location.place_id,
            name: eventInfo.location.name,
            address: eventInfo.location.formatted_address,
            lat: eventInfo.location.geometry.location.lat(),
            lng: eventInfo.location.geometry.location.lng(),
        } : eventInfo.location;

        const fetchOptions: RequestInit = {
            headers: { "Content-type": "application/json", },
            method: eventInfo.id ? "PATCH" : "POST",
            body: JSON.stringify({
                ...eventInfo,
                attendees: isAttending && userInfo?._id ? [userInfo?._id] : [],
                creator: userInfo?._id || null,
                isApproved: userInfo?.isAdmin,
                location: locationData,
                isAttending,
            })
        };

        lampPostFetch(
            eventInfo.id ? UPDATE_EVENT(eventInfo.id) : CREATE_EVENT,
            fetchOptions,
            (result: { _id: any; }) => {
                if (result) {
                    if (userInfo) {
                        getUpdatedUser();
                    }

                    if (onSuccess) {
                        onSuccess();
                    }
                }
            }
        );
    }

    const joinOrLeaveEvent = (eventId: string) => {
        lampPostFetch(
            JOIN_EVENT(eventId, userInfo._id),
            { method: "PATCH" },
            () => {
                getUpdatedUser();
            }
        );
    }

    const deleteEvent = (eventId: string, isModeratedEvent?: boolean) => {
        lampPostFetch(
            DELETE_EVENT(eventId),
            { method: "DELETE" },
            () => {
                if (isModeratedEvent) {
                    fetchModeratedEvents();
                } else {
                    onDeselectEvent();
                }
            }
        );
    }

    const acceptModeratedEvent = (eventId: string) => {
        setHasMoreEvents(true);

        lampPostFetch(
            ACCEPT_MODERATED_EVENT(eventId),
            { method: "PATCH" },
            () => {
                fetchModeratedEvents();
                fetchEvents();
            }
        );
    }

    const shareEvent = (recipients: string[]) => {
        lampPostFetch(
            SHARE_EVENT,
            {
                method: "PATCH",
                body: JSON.stringify({
                    eventId: selectedEvent,
                    recipients,
                    userId: userInfo?._id
                })
            },
        );
    }

    const onSelectEvent = (eventId: string) => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('eventId', eventId);
        setSearchParams(newParams);
        navigate(`?${newParams.toString()}`);
        setSelectedEvent(eventId);
    }

    const onDeselectEvent = () => {
        const newParams = new URLSearchParams(searchParams);
        newParams.delete('eventId');
        setSearchParams(newParams);
        navigate(`?${newParams.toString()}`);
        setSelectedEvent(null);
    }

    const onPageIncrease = () => {
        setPageNum(prevNum => prevNum + 1);
    }

    useEffect(() => {
        setHasMoreEvents(true);
        fetchEvents();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (pageNum > 0) {
            fetchEvents();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNum]);

    useEffect(() => {
        if (refetchWithFilters) {
            setHasMoreEvents(true);
            fetchEvents();
            onRefetchedWithFilters();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchWithFilters, userInfo]);

    useEffect(() => {
        if (userInfo?.isAdmin) {
            fetchModeratedEvents();
        }
    }, [userInfo?.isAdmin]);

    return (
        <EventsContext.Provider
            value={{
                events,
                moderationEvents,
                selectedEvent,
                eventsLoading,
                pageNum,
                fetchEvents,
                createOrEditEvent,
                joinOrLeaveEvent,
                acceptModeratedEvent,
                shareEvent,
                deleteEvent,
                onSelectEvent,
                onDeselectEvent,
                onPageIncrease,
                hasMoreEvents,
                viewingEvent: !!searchParams.get('eventId')
            }}
        >
            {children}
        </EventsContext.Provider>
    )
}