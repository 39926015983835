import { GET_SERVER } from "../components/common/constants/servers";

export const DEFAULT_HEADERS = {
    'Accept': 'application/json',
    "Content-type": "application/json",
    "ngrok-skip-browser-warning": "69420",
};

export const lampPostFetch = (
    endpoint: string,
    fetchOptions?: RequestInit,
    callback?: (result?: any) => void,
) => {
    try {
        fetch(`${GET_SERVER()}${endpoint}`, {
            method: "GET",
            headers: DEFAULT_HEADERS,
            ...fetchOptions,
        })
            .then((response) => response.json())
            .then((result) => callback ? callback(result) : result)
            .catch((error) => {
                console.error("Interior Fetch Error - Server Error: ", error);
                
                if (callback) {
                    callback();
                }
            }
        );
    } catch (error) {
        console.log(`Initial Fetch Error - General Error: [${error}]`);

        if (callback) {
            callback();
        }
    }
}