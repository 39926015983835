import { ReactElement, createContext, useContext, useState } from 'react';

type NavContextValues = {
    isNavMenuOpen: boolean;
    setIsNavMenuOpen: (navIsOpen: boolean) => void;
}

export const NavContext = createContext({} as NavContextValues);

export const useNavContext = () => useContext(NavContext);

export const NavContextProvider = ({ children }: { children: ReactElement }) => {
    const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

    return (
        <NavContext.Provider
            value={{
                isNavMenuOpen,
                setIsNavMenuOpen,
            }}
        >
            {children}
        </NavContext.Provider>
    )
}