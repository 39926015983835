export interface OptionType {
    id: number;
    name: string;
}

export const LAMP_POST_COLORS = {
    lightGrey: '#F5F5F5',
    amber: '#FFC200',
    deepBlue: '#002147',
    oliveGreen: '#556B2F',
    white: '#FFFFFF',
    destroRed: '#FF6347',
    link: '#0000EE',
};

export const LAMP_POST_FONT_INFO = {
    h1: {
        font: 32,
        lineHeight: 44
    },
    h2: {
        font: 28,
        lineHeight: 38
    },
    h3: {
        font: 24,
        lineHeight: 34
    },
    h4: {
        font: 22,
        lineHeight: 30
    },
    h5: {
        font: 20,
        lineHeight: 26
    },
    h6: {
        font: 18,
        lineHeight: 24
    },
    body: {
        font: 16,
        lineHeight: 20
    },
}

export const LAMP_POST_FONTS = {
    OPEN_SANS: 'OpenSans',
    QUICKSAND: 'Quicksand',
    PACIFICO: 'Pacifico',
    ROBOTO_MONO: 'RobotoMono',
};

export const LAMP_POST_Z_INDICES = {
    base: 100,
    high: 200
}

export const LAMPPOST_MOBILE_BREAKPOINTS = {
    desktop: 768
}
