const settingsStrings = {
    settingsScreen: {
        viewProfile: 'View Profile',
        updateProfile: 'Update Profile',
        myEvents: 'My Events',
        logOut: 'Log Out',
        personalize: 'Personal Information',
        emailNotifications: 'Email Notifications',
        accountManagement: 'Account Management',
    }
}

export default settingsStrings;