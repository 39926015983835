import strings from "../../../i18n/i18nStrings";

export const GENDER_OPTIONS = {
    male: {
        name: strings.demographics.gender.male,
        id: 0
    },
    female: {
        name: strings.demographics.gender.female,
        id: 1
    },
    transgender: {
        name: strings.demographics.gender.transgender,
        id: 2
    },
    nonBinary: {
        name: strings.demographics.gender.nonBinary,
        id: 3
    },
    other: {
        name: strings.demographics.gender.other,
        id: 4
    },
    decline: {
        name: strings.demographics.decline,
        id: 5
    },
};

export const ORIENTATION_OPTIONS = {
    heterosexual: {
        name: strings.demographics.orientation.heterosexual,
        id: 0
    },
    homosexual: {
        name: strings.demographics.orientation.homosexual,
        id: 1
    },
    bisexual: {
        name: strings.demographics.orientation.bisexual,
        id: 2
    },
    other: {
        name: strings.demographics.orientation.other,
        id: 3
    },
    decline: {
        name: strings.demographics.decline,
        id: 4
    },
};

export const EDUCATION_OPTIONS = {
    none: {
        name: strings.demographics.education.none,
        id: 0
    },
    highSchool: {
        name: strings.demographics.education.highSchool,
        id: 1
    },
    tradeSchool: {
        name: strings.demographics.education.tradeSchool,
        id: 2
    },
    associates: {
        name: strings.demographics.education.associates,
        id: 3
    },
    bachelors: {
        name: strings.demographics.education.bachelors,
        id: 4
    },
    masters: {
        name: strings.demographics.education.masters,
        id: 5
    },
    doctorate: {
        name: strings.demographics.education.doctorate,
        id: 6
    },
    professional: {
        name: strings.demographics.education.professional,
        id: 7
    },
    decline: {
        name: strings.demographics.decline,
        id: 8
    },
};

export const RACE_OPTIONS = {
    white: {
        name: strings.demographics.race.white,
        id: 0
    },
    black: {
        name: strings.demographics.race.black,
        id: 1
    },
    hispanic: {
        name: strings.demographics.race.hispanic,
        id: 2
    },
    asian: {
        name: strings.demographics.race.asian,
        id: 3
    },
    nativeAmericanAlaskan: {
        name: strings.demographics.race.nativeAmericanAlaskan,
        id: 4
    },
    pacificIslander: {
        name: strings.demographics.race.pacificIslander,
        id: 5
    },
    other: {
        name: strings.demographics.race.other,
        id: 6
    },
    decline: {
        name: strings.demographics.decline,
        id: 7
    },
}
