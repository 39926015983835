import {
    createContext,
    useContext,
    useEffect,
    useState
} from 'react';
import { FilterType } from '../types/FilterType';
import {
    FILTER_COSTS,
    FILTER_DISTANCE,
    FILTER_EVENT_TYPES,
    FILTER_TAGS
} from '../components/filters/filters.constants';
import { useAppContext } from './AppContext';

type FilterContextValues = {
    filters: FilterType;
    showFilters: boolean;
    onClearFilters: () => void;
    onSubmitFilters: (updatedFilters: FilterType) => void;
    setShowFilters: (showFilters: boolean) => void;
    onCloseFilters: () => void;
    filtersAreApplied: boolean;
    refetchWithFilters: boolean;
    onRefetchedWithFilters: () => void;
}

const DEFAULT_FILTERS = {
    eventType: [FILTER_EVENT_TYPES.allEvents],
    name: '',
    location: null,
    distance: [FILTER_DISTANCE.tenMiles],
    startDateTime: new Date().getTime(),
    cost: [FILTER_COSTS.any],
    tags: [],
    ages: [],
    groups: [],
}

export const FilterContext = createContext({} as FilterContextValues);

export const useFilterContext = () => useContext(FilterContext);

export const FilterContextProvider = ({ children }) => {
    const {
        userInfo,
        isSignedIn
    } = useAppContext();

    const [filters, setFilters] = useState<FilterType>(DEFAULT_FILTERS);
    const [filtersAreApplied, setFiltersAreApplied] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [refetchWithFilters, setRefetchWithFilters] = useState(false);

    useEffect(() => {
        if (isSignedIn) {
            const userInitialTags = userInfo?.interests?.map(interest => Object.values(FILTER_TAGS).find(tag => tag.id === interest)) || [];
            setFilters({
                ...DEFAULT_FILTERS,
                tags: userInitialTags
            });
            setFiltersAreApplied(!!userInitialTags);
            setRefetchWithFilters(true);
        }
    }, [isSignedIn, userInfo?.interests]);


    const onSubmitFilters = (updatedFilters: FilterType) => {
        setShowFilters(false);
        setFiltersAreApplied(true);
        setRefetchWithFilters(true);
        setFilters(updatedFilters);
    }

    const onClearFilters = () => {
        setShowFilters(false);
        setFiltersAreApplied(false);
        setRefetchWithFilters(true);
        setFilters(DEFAULT_FILTERS);
    }

    const onCloseFilters = () => {
        setShowFilters(false);
    }

    const onRefetchedWithFilters = () => setRefetchWithFilters(false);

    return (
        <FilterContext.Provider
            value={{
                filters,
                showFilters,
                setShowFilters,
                onClearFilters,
                onSubmitFilters,
                onCloseFilters,
                filtersAreApplied,
                refetchWithFilters,
                onRefetchedWithFilters
            }}
        >
            {children}
        </FilterContext.Provider>
    );
}