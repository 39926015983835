
import { useEventsContext } from "../../contexts/EventsContext";
import strings from "../../i18n/i18nStrings";
import Modal from "../../ui/modal/Modal";
import BackHeader from "../mobile/backHeader/BackHeader";
import styles from "./CreateOrEdit.module.scss";
import CreateOrEditEventInputs from "./inputs/CreateOrEditEventInputs";

const CreateOrEditEvent = ({
    showCreateEvent,
    onClose,
    eventInfo,
}: {
    showCreateEvent: boolean;
    onClose: () => void;
    eventInfo?: any;
}) => {
    const { fetchEvents } = useEventsContext();

    return (
        <>
            {
                showCreateEvent && (
                    <div className={styles.createEventMobile}>
                        <>
                            <BackHeader
                                onBack={onClose}
                                backText={strings.eventDetailsScreen.backtoEvent}
                            />
                            <div className={styles.createForm}>
                                <CreateOrEditEventInputs
                                    onCancel={onClose}
                                    onSuccess={fetchEvents}
                                    eventInfo={eventInfo}
                                />
                            </div>
                        </>
                    </div>
                )
            }
            <Modal
                isOpen={showCreateEvent}
                onClose={onClose}
            >
                <CreateOrEditEventInputs
                    onCancel={onClose}
                    onSuccess={fetchEvents}
                    eventInfo={eventInfo}
                />
            </Modal>
        </>
    )
}

export default CreateOrEditEvent;