import { useEffect, useState } from "react";
import strings from "../../../i18n/i18nStrings";
import Input from "../../../ui/input/Input";
import { lampPostFetch } from "../../../utils/fetchHelpers";
import Loader from "../../loader/Loader";
import styles from "./CreateOrEditGroupInputs.module.scss";
import { GET_USERS_BY_EMAIL } from "../../common/constants/endpoints";
import { useGroupContext } from "../../../contexts/GroupContext";
import { useAppContext } from "../../../contexts/AppContext";
import { GroupType } from "../../../types/GroupType";
import Button, { BUTTON_VARIANTS } from "../../../ui/button/Button";

const CreateOrEditGroupInputs = ({
    onBack,
    groupInfo,
}: {
    onBack: () => void;
    groupInfo?: GroupType;
}) => {
    const { userInfo } = useAppContext();
    const { createOrEditGroup } = useGroupContext();

    const [isSubmittingGroup, setIsSubmittingGroup] = useState(false);
    const [isSubmitEnabled, isSetSubmitEnabled] = useState(true);

    const [groupName, setGroupName] = useState(groupInfo?.name || '');
    const [groupMembers, setGroupMembers] = useState('');

    useEffect(() => {
        const getGroupMemberData = (groupSubset) => groupSubset?.map(member => {
            if (member._id) {
                return member.email;
            }

            return member;
        }) || [];

        const currMembers = getGroupMemberData(groupInfo?.members);
        const currPendings = getGroupMemberData(groupInfo?.pending);

        setGroupMembers(currMembers.concat(currPendings).sort().join(', '));

    }, [groupInfo?.members, groupInfo?.pending]);

    useEffect(() => {
        isSetSubmitEnabled(
            !!groupName &&
            !!groupMembers
        );
    }, [groupName, groupMembers]);

    const submitEvent = () => {
        setIsSubmittingGroup(true);

        lampPostFetch(
            GET_USERS_BY_EMAIL(groupMembers.replace(/\s/g, "")),
            { method: "GET" },
            (result) => {
                const groupMembers = result;
                createOrEditGroup(
                    {
                        _id: groupInfo?._id || null,
                        name: groupName,
                        groupLeader: groupInfo?.groupLeader || userInfo._id,
                        members: groupMembers,
                        events: groupInfo?.events || []
                    },
                    () => {
                        setIsSubmittingGroup(false);
                        onBack();
                    }
                );
            }
        );
    }

    return (
        <>
            {isSubmittingGroup && <Loader />}
            <div>
                <div className={styles.groupPageTitle}>
                    <p>{strings.groupScreen[groupInfo?._id ? 'editGroup' : 'createGroup']}</p>
                </div>
                <div className={styles.groupInfoContainer}>
                    <Input
                        label={strings.groupScreen.groupName}
                        text={groupName}
                        onChange={setGroupName}
                    />
                    <Input
                        label={strings.groupScreen.groupMembers}
                        text={groupMembers}
                        onChange={setGroupMembers}
                        numberOfLines={5}
                    />
                </div>
            </div>
            <div className={styles.submissionButtons}>
                <Button
                    onPress={submitEvent}
                    disabled={!isSubmitEnabled}
                >
                    <span>{strings.common.submit}</span>
                </Button>
                <Button
                    onPress={onBack}
                    roleVariant={BUTTON_VARIANTS.SECONDARY}
                >
                    <span>{strings.common.cancel}</span>
                </Button>
            </div>
        </>
    )
}

export default CreateOrEditGroupInputs;