export const urlValidator = (urlString) => {
    const urlPattern = /((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*/g;
    return urlPattern.test(urlString);
}

export const urlFormatter = (urlString) => {
    const httpPattern = /(?:https?):\/\/'/g;

    if (urlValidator(urlString) && httpPattern.test(urlString)) {
        return urlString;
    } else if (urlValidator(urlString) && !httpPattern.test(urlString)) {
        return `https://${urlString}`;
    }

    return 'https://lamppost.app'; // TODO - Direct to error page since it should be a working link
}